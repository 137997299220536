import { observer } from "mobx-react-lite";
import React, { useContext, useState, useEffect } from "react";
import ReactModal from "react-modal";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import firebase from "firebase";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { AuthContext } from "./AuthContext";

const ReportModal = observer((props) => {
  const WidgetStore = useContext(WidgetStoreContext);
  const EditorStore = useContext(EditorStoreContext);
  const { currentUser } = useContext(AuthContext);

  const [confirm, setConfirm] = useState(false);
  const [reportTitle, setReportTitle] = useState("");
  const [reportDescription, setReportDescription] = useState("");
  const [reportEmail, setReportEmail] = useState("");
  const [reportErrorMessage, setReportErrorMessage] = useState("");

  // Rate Limiting
  const [submissionCount, setSubmissionCount] = useState(0);
  const maxSubmissions = 5;

  // Loading spinner state
  const [isLoading, setIsLoading] = useState(false);

  let flowId = 
    WidgetStore.currentlySelectedFeedFlow === undefined
      ? EditorStore.flowId
      : WidgetStore.currentlySelectedFeedFlow;

  // Send admin email
  const sendEmailToAdmins = async () => {
    try {
      // Customize the subject and HTML as needed
      const requestBody = {
        subject: "Flowroom Flow Reported",
        html: `
          <h2>Dear Admins,</h2>
          <p>A report about a flow has been submitted. Please check the admin dashboard to review it.</p>
          <p>Best regards,<br/>Flowroom Team</p>
        `,
      };

      const response = await fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/sendEmailToAdmins",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (response.ok) {
        console.log("Email sent successfully:", data);
      } else {
        console.error("Error sending email:", data);
      }
    } catch (err) {
      console.error("Request failed:", err);
    }
  };

  const saveReport = async () => {
    // Rate limit check
    if (submissionCount >= maxSubmissions) {
      setReportErrorMessage(
        "You have reached the maximum number of submissions. Please wait before submitting again."
      );
      return;
    }

    // Validation: title, description, and email all required
    if (!reportTitle.trim() || !reportDescription.trim() || !reportEmail.trim()) {
      setReportErrorMessage("Please fill in the title, description, and a valid email.");
      return;
    }

    setIsLoading(true); // Start spinner

    try {
      // Check if a report for the same flowId by this user already exists
      const reportsRef = firebase.firestore().collection("flowReports");
      const existingReportQuery = await reportsRef
        .where("flowId", "==", flowId)
        .where("currentUser", "==", currentUser.displayName)
        .get();

      // if (!existingReportQuery.empty) {
      //   setReportErrorMessage("You already made a report for this flow.");
      // }

      // Add new report if no existing one
      await reportsRef.add({
        title: reportTitle.trim(),
        description: reportDescription.trim(),
        email: reportEmail.trim(), // Now required
        flowId: flowId,
        timestamp: firebase.firestore.FieldValue.serverTimestamp(),
        username: currentUser.displayName,
        currentUser: currentUser.displayName, // if you want this for consistency
      });

      await sendEmailToAdmins();

      setSubmissionCount(submissionCount + 1);
      setConfirm(true);

      // Reset submission limit after 30 seconds
      setTimeout(() => setSubmissionCount(0), 30000);

    } catch (error) {
      console.error("Error saving report: ", error);
      setReportErrorMessage("There was an error submitting your report. Please try again.");
    } finally {
      setIsLoading(false); // Stop spinner
    }
  };

  return (
    <ReactModal
      ariaHideApp={true}
      isOpen={WidgetStore.showReportModal}
      closeTimeoutMS={200}
      onAfterClose={() => {
        // Reset fields/modal after closing
        setConfirm(false);
        setReportTitle("");
        setReportEmail("");
        setReportDescription("");
        setReportErrorMessage("");
        WidgetStore.setShowReportModal(false);
      }}
      style={{
        overlay: {
          padding: 0,
          zIndex: 1000000,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.8)",
          opacity: 1,
        },
        content: {
          position: "relative",
          top: "0px",
          left: "0px",
          right: "0px",
          bottom: "0px",
          border: "none",
          backgroundColor: "#1C1C1C",
          overflow: "hidden",
          borderRadius: "20px",
          outline: "none",
          padding: "0px",
          zIndex: 1000000,
          margin: "auto",
          width: "calc(100% - 50px)",
          maxWidth: "450px",
          borderRadius: 20,
        },
      }}
    >
      {/* Spinner keyframes and style */}
      <style>
        {`
          @keyframes spin {
            0%   { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
          .spinner {
            border: 2px solid #f3f3f3;
            border-top: 2px solid #444;
            border-radius: 50%;
            width: 14px;
            height: 14px;
            margin-right: 8px;
            animation: spin 1s linear infinite;
          }
        `}
      </style>

      {/* Header */}
      <div
        style={{
          display: "flex",
          position: "absolute",
          width: "100%",
          padding: "7px 25px",
          borderBottom: "1px solid #3E3E3E",
          alignItems: "center",
        }}
      >
        <img
          style={{ width: 20, height: 20 }}
          src="/images/report-modal-icon.svg"
        />
        <div>
          <p
            style={{
              color: "white",
              fontFamily: "quicksand",
              fontWeight: 700,
              fontSize: 14,
              opacity: 0.7,
              marginBottom: 10,
              marginTop: 10,
              marginLeft: 10,
            }}
          >
            Report this flow
          </p>
        </div>
        <div
          onClick={() => {
            WidgetStore.setShowReportModal(false);
          }}
          style={{ cursor: "pointer", position: "absolute", right: 20 }}
        >
          <img src="/images/close-circle-report.svg" style={{ bottom: 17 }} />
        </div>
      </div>

      {/* Body */}
      {!confirm ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: 55,
            padding: "20px 25px",
            paddingBottom: 30,
          }}
        >
          <p
            style={{
              fontFamily: "Quicksand",
              fontWeight: 500,
              fontSize: 14,
              color: "#FFFFFF",
              opacity: 0.7,
              marginTop: 10,
              marginBottom: 12,
            }}
          >
            Report title
          </p>
          <input
            className="formInputOutline"
            type="text"
            onChange={(e) => setReportTitle(e.target.value)}
            style={{
              height: 40,
              width: "100%",
              marginBottom: 5,
              borderRadius: 10,
              paddingLeft: 10,
              backgroundColor: "#323232",
              border: "none",
              fontSize: 15,
              color: "white",
              fontFamily: "quicksand",
              fontWeight: "bold",
            }}
            placeholder="Report title"
            value={reportTitle}
          />
          <p
            style={{
              fontFamily: "Quicksand",
              fontWeight: 500,
              fontSize: 14,
              color: "#FFFFFF",
              marginTop: 12,
            }}
          >
            Description
          </p>

          <textarea
            className="formInputOutline"
            onChange={(e) => setReportDescription(e.target.value)}
            style={{
              height: 170,
              width: "100%",
              borderRadius: 10,
              background: "#323232",
              marginTop: 15,
              marginBottom: 10,
              resize: "none",
              border: "none",
              padding: 10,
              fontSize: 14,
              color: "white",
              fontFamily: "quicksand",
              fontWeight: "bold",
            }}
            value={reportDescription}
            placeholder="Please give as much detail as possible..."
          />
          <p
            style={{
              fontFamily: "Quicksand",
              fontWeight: 500,
              fontSize: 14,
              color: "#FFFFFF",
              opacity: 0.7,
            }}
          >
            Email
          </p>
          <input
            className="formInputOutline"
            type="email"
            onChange={(e) => setReportEmail(e.target.value)}
            style={{
              height: 33,
              width: "100%",
              marginBottom: 5,
              borderRadius: 10,
              paddingLeft: 10,
              background: "#323232",
              border: "none",
              marginTop: 15,
              fontSize: 15,
              color: "white",
              fontFamily: "quicksand",
              fontWeight: "bold",
            }}
            placeholder="Email"
            value={reportEmail}
          />
          <p
            style={{
              color: "#FF5C5C",
              fontSize: 12,
              fontFamily: "quicksand",
              textAlign: "center",
            }}
          >
            {reportErrorMessage}
          </p>

          {/* Submit button with spinner */}
          <div
            onClick={!isLoading ? saveReport : undefined}
            style={{
              backgroundColor: "#4FD975",
              height: 35,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 10,
              marginTop: 15,
              cursor: isLoading ? "not-allowed" : "pointer",
            }}
          >
            {isLoading && <div className="spinner" />}
            <p
              style={{
                color: "#222222",
                fontFamily: "quicksand",
                fontSize: 15,
              }}
            >
              {isLoading ? "Submitting..." : "Submit"}
            </p>
          </div>
        </div>
      ) : (
        <div
          style={{
            height: "calc(100vh - 400px)",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            marginTop: 55,
            padding: "20px 25px",
            maxHeight: 500,
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              color: "white",
              fontFamily: "quicksand",
              fontSize: 30,
              textAlign: "center",
            }}
          >
            Thank you!
          </p>
          <p
            style={{
              color: "white",
              fontFamily: "quicksand",
              fontSize: 20,
              textAlign: "center",
              marginBottom: 50,
              fontWeight: 100,
            }}
          >
            Your report has been submitted.
          </p>
        </div>
      )}
    </ReactModal>
  );
});

export default React.memo(ReportModal);
