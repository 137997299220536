import { observer } from "mobx-react-lite";
import React, { useContext, useState, useEffect } from "react";
import { WidgetStoreContext } from "../../store/WidgetStore.js";
import CalendarComponent from "./CalendarComponent.js";
import CheckboxComponent from "./CheckboxComponent.js";
import ColorComponent from "./ColorComponent.js";
import ImageComponent from "./ImageComponent.js";
import RadioComponent from "./RadioComponent.js";
import SelectComponent from "./SelectComponent.js";
import SliderComponent from "./SliderComponent.js";
import SwitchComponent from "./SwitchComponent.js";
import TextComponent from "./TextComponent.js";
import PromptUI from "./PromptUI.js";
import PasswordProtect from "./PasswordProtect.js";

import BotWidget from "./BotWidget.js"
import { faChevronLeft, faMicrophone } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import AITrainer from "./AITrainer.js";
import BotManager from "./BotManager.js"
// import AIWidgetRemixModal from "./AIWidgetRemixModal.js";
import DragDropNCrop from "./DragDropNCrop";

// store
import "react-datepicker/dist/react-datepicker.css";
import "react-image-crop/dist/ReactCrop.css";

import { firebase } from "../firebase/firebase";
import '../styles/MicStyles.css'; // Assuming styles are saved in this CSS file

let currentUser = firebase.auth().currentUser;
let firestore = firebase.firestore();
let blockedUsersArr = [];
let widgets = [];
let optionsJump = [];
let colorSelected = [];
let initialCall = false;
let addImage;
let addImageFuncCalls = 0;
let addTextFuncCalls = 0;
let ColorInstance = -1;
let getText;
let searchArray;
let textFromResponse;
let functionToCall;
let imageToReplaceArray;
let searchQueryIndex = 0;
let imageIndexSelect = 0;
let alreadyLoadedModel = false;
let textIndexSelect = 0;
let colorIndexSelect = 0;
let sliderIndexSelect = 0;
let switchIndexSelect = 0;
let selectIndexSelect = 0;
let checkboxIndexSelect = 0;
let calendarIndexSelect = 0;
let rateCounter = 0;
let IndexSelect = 0;
let typingTimer;
let actualInstanceIncrement = 0;
let startingTextIncrement = [];
let startingImageIncrement = [];
let hiddenWidgetsAlreadyLoaded = false;
let intervalId;
window.recordingData = '';
window.isRecording = false;
const RemixWidgetModal = observer((props) => {
  const WidgetStore = useContext(WidgetStoreContext);
  const [confirm, setConfirm] = useState(false);

  const [copiedClipboardShare, setCopiedClipboardShare] = useState(false);
  const [copiedClipboardEmbed, setCopiedClipboardEmbed] = useState(false);
  const [openClose, setOpenClose] = useState(false);
  const [openBlockError, setOpenBlockError] = useState(false);
  const [blockedUsers, setBlockedUsers] = useState(false);
  const [showHiddenRemixWidgets, setShowHiddenRemixWidgets] = useState(false);
  const [showHiddenOption, setShowHiddenOption] = useState(false);
  const [missingWidgets, setMissingWidgets] = useState(false);
  const [peak, setPeak] = useState(0);
  const [color, setColor] = useState('#00ff00'); // Initial color green
  const [isActive, setIsActive] = useState(false); // State to track if the mic is active
  const [image, setImage] = useState("");
  let analyser;

  const [recordingData, setRecordingData] = useState({});  // Initialize your data store

  useEffect(()=>{
    if(props.imgSrc !== null) {
    localStorage.setItem(`${props.imageId}`, props.imgSrc);
    }
  },[])

  // useEffect(()=>{
  //   if(WidgetStore.dropImageValue !== null) {
  //   setTimeout(()=>{
  //   //setImage(WidgetStore.dropImageValue.src);
  //   if(document.getElementById(props.imageId) !== null) {
  //   document.getElementById(props.imageId).style.backgroundImage = `url(${WidgetStore.dropImageValue.src})`
  //   }
  //   },100)
  //   }
  // },[WidgetStore.swipeIndex]) 
  useEffect(() => {
    // Expose the state to the global window object
    window.recordingData = {
      getData: () => recordingData,
      setData: setRecordingData
    };

    // Cleanup function to remove the reference when the component unmounts
    return () => {
      delete window.recordingData;
    };
  }, [recordingData]);  // Ensure the effect runs whenever recordingData changes

  // useEffect(()=>{
  //   let img = localStorage.getItem(`${props.imageId}`);
  //   console.log('imge selected', img);
  //     if(props.imgSrc !== undefined && props.imgSrc !== null && props.imgSrc !== '') {
  //       // setImage(WidgetStore.dropImageValue.src);
  //         if(document.getElementById(props.imageId) !== null) {
  //           document.getElementById(props.imageId).style.backgroundImage = `url(${props.imgSrc})`
  //         }
       

  //     } else {
  //       setImage(null);
  //     }
 
    
  // },[image])

  useEffect(()=>{
    let img = localStorage.getItem(`${props.imageId}`);
    console.log('imge selected', img);

    if(props.imgSrc !== null && props.imgSrc !== '') {
      // setImage(WidgetStore.dropImageValue.src);
      console.log('props.imgSrc', props.imgSrc);
        if(document.getElementById(props.imageId) !== null) {
          document.getElementById(props.imageId).style.backgroundImage = `url(${img})`
        }

    } else {
      setImage(null);
    }


    if(props.imgSrc == undefined || props.imgSrc == null || props.imgSrc == '') {
    // setImage(img);
      if(document.getElementById(props.imageId) !== null) {
        document.getElementById(props.imageId).style.backgroundImage = `url(${img})`
      }
   

    
    } 
    
  },[WidgetStore.swipeIndex])


  //  useEffect(()=>{
  //   let img = localStorage.getItem(`${props.imageId}`);
  //   console.log('imge selected', img);
  //   if(props.imgSrc !== undefined && props.imgSrc !== null && props.imgSrc !== '') {
  //     // setImage(props.imgSrc);
  //     if(document.getElementById(props.imageId) !== null) {
  //       document.getElementById(props.imageId).style.backgroundImage = `url(${props.imgSrc})`
  //       }
      
  //     return;
  //   }
  //   if(img !== null) {
    
  //   // setImage(img);
  //   if(document.getElementById(props.imageId) !== null) {
  //     document.getElementById(props.imageId).style.backgroundImage = `url(${props.imgSrc})`
  //     }
    
  //   } else {
  //     if(document.getElementById(props.imageId) !== null) {
  //       document.getElementById(props.imageId).style.backgroundImage = `url(${props.imgSrc})`
  //       }
      
  //    setImage(null);
      
 
  //   }
  //  },[image])

  useEffect(() => {
    if (!isActive) return;

    const audioContext = new (window.AudioContext || window.webkitAudioContext)();
    navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => {
            const mic = audioContext.createMediaStreamSource(stream);
            analyser = audioContext.createAnalyser();
            mic.connect(analyser);
            const dataArray = new Uint8Array(analyser.frequencyBinCount);

            const draw = () => {
                if (!isActive) return; // Stop the animation if not active
                requestAnimationFrame(draw);
                analyser.getByteFrequencyData(dataArray);
                let maxVal = Math.max(...dataArray);
                setPeak(maxVal);
            };
            draw();
        })
        .catch(e => console.error(e));

    return () => {
        if (audioContext) {
            audioContext.close(); // Proper cleanup on component unmount or deactivation
        }
    };
}, [isActive]);


  // useEffect(() => {
  //   if (WidgetStore.widgets.length === 0) {
  //     setMissingWidgets(true);
  //   } else {
  //     setMissingWidgets(false);
  //   }
  // }, []);

console.log('props', props)
  // mobile layout
  let isMobile = window.matchMedia("(max-width: 768px)").matches;
  const getBlockedUsers = async () => {
    if (myCurrentUserName === undefined) {
      return;
    }
    let myCurrentUserName = JSON.parse(
      localStorage.getItem("flowroom-user")
    ).displayName;
    setBlockedUsers([]);

    let snapshot = await firebase
      .firestore()
      .collection("blocked")
      .where("user", "==", myCurrentUserName)
      .limit(10)
      .get();
    snapshot.forEach((doc) => {
      let data = doc.data();
      let blockedUserObj = {
        blocked: data.blocked,
        user: data.user,
        profilePic: data.profilePic,
      };
      blockedUsersArr.push(blockedUserObj);
    });
    setBlockedUsers(blockedUsersArr);
  };

function updatePeak() {
    if (window.parent && typeof window.parent.getPeakMeter === 'function') {
        const peakValue = window.parent.getPeakMeter();
        document.getElementById('peak').style.width = peakValue + '%';
        // const red = Math.min(255, Math.round(2.55 * peakValue));
        // const green = Math.max(0, 255 - Math.round(2.55 * peakValue));
        const intensity = Math.round(peakValue);
        if (intensity % 2 === 0) {
            setColor('#FFFFFF'); // White at even intensities
        } else {
            setColor('#F06263'); // Specified shade of red at odd intensities
        }
        // setPeak(peakValue)
    }
}

function startUpdatingPeak() {
  // if (intervalId) {
  // clearInterval(intervalId);
  // }
  intervalId = setInterval(updatePeak, 100);
}
  
  function stopUpdatingPeak() {
    clearInterval(intervalId);
  }

  const handleMicClick = () => {
    setIsActive(!isActive); // Toggle the active state of the microphone
    if (!isActive) {
        setColor('#F06263'); // Set initial active color to red
    }
};

const stopRecording = async () => {
  return await window.stopRecording();
}


// Function to send data to the iframe
const setAudioDataInIframe = (audioData) => {
  let iframe = document.getElementById("undefined_output_frame")
   // if (iframe) {
        const message = {
            type: 'setAudioData',
            data: audioData
        };
        // Post message to the iframe, replace URL with your iframe's origin
        iframe.contentWindow.postMessage(message, "*");
   // }
};

window.toggleActiveMicriphone = async () => {
  setIsActive(!isActive);
  try{
  let iframe = document.getElementById("undefined_output_frame")

  if(isActive === false) {
    console.log('startFunction', props.startFunction)
    iframe.contentWindow[props.startFunction]();
  } else {
    iframe.contentWindow[props.stopFunction]();
    props.closeRemixWidgetModal();
  }
}catch(e) {
  
}
  

};


function base64ToBlob(base64, mimeType) {
  const byteCharacters = atob(base64.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: mimeType });
}


const uploadImageFile = async (blob) => {
return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
        const base64Data = reader.result.split(',')[1];
        const bodyData = JSON.stringify({
            imageData: base64Data,
            mimeType: blob.type
        });

        fetch('https://us-central1-flowroom-fd862.cloudfunctions.net/uploadImageHTTP', {
            method: 'POST',
            body: bodyData,
            headers: {
                'Content-Type': 'application/json'
            },
        })
        .then(response => response.json())
        .then(data => {
            if (data.imageUrl) {
                resolve(data.imageUrl);
            } else {
                reject('No image URL returned');
            }
        })
        .catch(error => {
            reject(error);
        });
    };

    reader.onerror = error => reject(error);
    reader.readAsDataURL(blob);
});
};



  return (
    <div
      className={`remix-modal-widget-wrapper ${
        props.showRemixWidgetModal == true
          ? "open-remix-widget-modal"
          : ""
      }`}
      style={{ boxShadow: "0px 8px 18px rgba(0, 0, 0, 0.25)" }}
    >
      <div className="remix-widget-modal" style={{ borderRadius: 0, overflow:'hidden',   
     }}>
        <div
          // className={{
          //   base: "remix-base",
          //   beforeClose: "remix-before",
          //   afterOpen: "remix-open",
          // }}
          style={{
            display: props.showRemixWidgetModal == true ? "flex" : "none",
            backgroundColor: "#1C1C1C",
            borderRadius: 7,
            zIndex: 2,
            pointerEvents:
            props.showRemixWidgetModal == true ? "all" : "none",
            position: "relative"
          }}
        >
        <div style={{borderBottom:'1px solid rgb(28, 28, 28)', height:40, width:'100%', position:'absolute', backgroundColor:'#1c1c1c', zIndex:1000}}>
          <img onClick={() => {
              props.closeRemixWidgetModal();
              // WidgetStore.setGetCodeFromIframe(true);
            }} src="../close-circle.svg" style={{height:12, width:12, position:'absolute',right:15, top:15, cursor:'pointer', zIndex:10}}/>
            </div>
          <div
            style={{
              position: "relative",
              width: "100%",
              marginTop:40
            }}
          >
            {missingWidgets === true ? (
              <div
                style={{
                  height: 150,
                  width: "100%",
                  maxWidth: 200,
                  background: "#3F3F3E",
                  overflow: "hidden",
                  fontFamily: "quicksand",
                  color: "white",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                <p style={{ fontFamily: "quicksand", color: "white" }}>
                  Missing Widget
                </p>
              </div>
            ) : (
              ""
            )}

            {(() => {
              if (props.widget === "TextInput") {
                return (
                  <div
                    style={{
                      borderBottom:
                        WidgetStore.showRemixWidgetModal == false
                          ? "3px solid #222222"
                          : "0px",
                    }}
                  >
                    <TextComponent
                      functionName={props.functionName}
                      widget={props.widget}
                      label={props.widgetLabel}
                      value={props.value}
                    />
                  </div>
                );
              }
              if (props.widget === "ChatUI") {
                return (
                  <div
                    style={{
                      margin: 0,
                      borderBottom:
                        WidgetStore.showRemixWidgetModal == false
                          ? "3px solid #222222"
                          : "0px",
                      paddingBottom:
                        WidgetStore.showRemixWidgetModal == false ? 0 : 0,
                        width:'100%'
                    }}
                  >
                    <AITrainer
                      functionName={props.functionName}
                      widget={props.widget}
                      widgetLabel={props.widgetLabel}
                      value={props.value}
                      closeModal={() => {
                        WidgetStore.setShowRemixWidgetModal(false);
                      }}
                      flowId={props.flowId}
                      deductCreditFrom={
                        props.deductCreditFrom == null ||
                        props.deductCreditFrom == undefined
                          ? 0
                          : props.deductCreditFrom
                      }
                      username={props.username}
                      uid={props.uid}
                      trainMode={props.trainMode}
                      items={props.items}
                    />
                  </div>
                );
              }

              if (props.widget === "ColorSelect") {
                return (
                  <div
                    style={{
                      borderBottom: "3px solid #222222",
                    }}
                  >
                    <ColorComponent
                      functionName={props.functionName}
                      widget={props.widget}
                      label={props.widgetLabel}
                      value={props.value}
                    />
                  </div>
                );
              }
              if (props.widget === "PromptUI") {
                return (
                  <div
                    style={{
                      borderBottom: "3px solid #222222",
                    }}
                  >
                    <PromptUI
                      functionName={props.functionName}
                      widget={props.widget}
                      label={props.widgetLabel}
                      value={props.value}
                    />
                  </div>
                );
              }
              if (props.widget === "PasswordProtect") {
                return (
                  <div
                    style={{
                      borderBottom: "3px solid #222222",
                    }}
                  >
                    <PasswordProtect
                      functionName={props.functionName}
                      widget={props.widget}
                      label={props.widgetLabel}
                      value={props.value}
                    />
                  </div>
                );
              }
              if (props.widget === "Slider") {
                return (
                  <div
                    style={{
                      borderBottom: "3px solid #222222",
                    }}
                  >
                    <SliderComponent
                      functionName={props.functionName}
                      widget={props.widget}
                      label={props.widgetLabel}
                      value={props.value}
                    />
                  </div>
                );
              }
              if (props.widget === "ImageUploader") {
                return (
                  <div
                    style={{
                      borderBottom: "0px solid #222222",
                      width: "100%",
                      maxWidth: 270,
                    }}
                  >
                    
                         {WidgetStore.swipeIndex !== 1 ? (
                      <ImageComponent
                        functionName={props.functionName}
                        label={props.widgetLabel}
                        imageId={props.imageId}
                        imgSrc={image}
                        // replaceInstance={widget.replaceInstance}
                        // replace={widget.replace}
                        // actualInstance={i}
                        // value={widget.value}
                        // instanceOfWidget={widget.instanceOfWidget}
                        // isLocked={widget.isLocked}
                        // advancedOptions={WidgetStore.advancedOptions}
                        // hideOptions={true}
                      />
                    ) : (
                      ""
                    )}
                                      <div style={{borderTop:'1px solid #323232'}}></div>

                    <div style={{height: '100%',
    /* min-height: 261px; */
    maxWidth: 300,
    paddingLeft: 20,
    paddingRight: 20,
    marginTop: WidgetStore.swipeIndex === 1 ? 45 : 0,
    marginBottom: WidgetStore.swipeIndex === 1 ? 20 : 0}}>
                    {WidgetStore.swipeIndex === 1 ? (<div>
                      <div>
                      {WidgetStore.dropImageValue !== null ? (<Button
                        id="doneBack"
                        onClick={async () => {
                          // alert(WidgetStore.replaceValue);
                          // alert(WidgetStore.widgetValueLength);

                          // alert(WidgetStore.replaceValue);

                          // alert(WidgetStore.actualInstance);

                          // alert(WidgetStore.editorSelected);
                          // document.getElementById(
                          //   `add_${WidgetStore.widgetActualInstance}`
                          // ).style.display = "none";
                          // document.getElementById(
                          //   `add_image_${WidgetStore.widgetActualInstance}`
                          // ).style.display = "none";
                          WidgetStore.setReplaceValue("");
                          WidgetStore.setWidgetValueLength("");

                          // WidgetStore.setWidgetActualInstance("");

                          // WidgetStore.setEditorSelected("");
                          WidgetStore.setSwipeIndex(0);
                          WidgetStore.setCurrentlySelectedImage("");
                          WidgetStore.setTextValue("");

                          let iframe = document.getElementById("undefined_output_frame");
                      
                          // Assuming the MIME type is known, e.g., 'image/png'
                          let blob = base64ToBlob(WidgetStore.dropImageValue, 'image/png');
                          // setIsLoading(true);
                          //Replace `uploadImageFile` call to accept a Blob instead of a Base64 string
                          const imageUrl = await uploadImageFile(blob); // Make sure this function is adjusted to handle a Blob
                          console.log('widget img',imageUrl);
                       
                          try {
                          iframe.contentWindow[props.functionName](imageUrl);
                          localStorage.setItem(`${props.imageId}`, imageUrl);
                          } catch(err) {}

                        }}
                        style={{
                          borderRadius: "20px",
                          width: "100%",
                          height: 25,
                          border: "0px",
                          backgroundColor: "#4FD975",
                          fontWeight: 700,
                          fontSize: 15,
                          marginBottom: 15,
                          display: "flex",
                          justifyContent: "flex-start",
                        }}
                        color="primary"
                        variant="contained"
                      >
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          color="#424242"
                          size={"1x"}
                          style={{ display: "flex", marginLeft: 5 }}
                        />
                        <p
                          style={{
                            fontFamily: "quicksand",
                            color: "#424242",
                            marginLeft: 5,
                          }}
                        >
                          Save & Go Back
                        </p>
                      </Button>):""}
                      </div>
                      <div onClick={()=>{
                                                  WidgetStore.setSwipeIndex(0);

                      }} style={{          borderRadius: "20px",
                      width: "100%",
                      height: 25,
                      border: "0px",
                      backgroundColor: "#4FD975",
                      fontWeight: 700,
                      fontSize: 15,
                      marginBottom: 15,
                      display: "flex",
                      justifyContent: "center", cursor:'pointer'}}><p
                      style={{
                        fontFamily: "quicksand",
                        color: "#424242",
                        marginLeft: 5,
                      }}
                    >
                      Go Back
                    </p></div>
                      </div>
                    ) : (
                      ""
                    )}
          
                    {WidgetStore.swipeIndex === 1 ? (
                      <DragDropNCrop
                        selectedImageName={WidgetStore.currentlySelectedImage}
                        functionName={props.functionName}
                        imageId={props.imageId}
                   
                        // actualInstance={i}
                       
                      />
                    ) : (
                      ""
                    )}
                    </div>
                  </div>
                );
              }
              if (props.widget === "Checkbox") {
                return (
                  <div
                    style={{
                      borderBottom: "0px solid #222222",
                    }}
                  >
                    <CheckboxComponent
                      functionName={props.functionName}
                      widget={props.widget}
                      label={props.widgetLabel}
                      value={props.value}
                    />
                  </div>
                );
              }
              if (props.widget === "Radio") {
                return (
                  <div
                    style={{
                      marginTop: 45,
                      borderTop: "3px solid #222222",
                      paddingBottom: 20,
                    }}
                  >
                    <RadioComponent
                      functionName={props.functionName}
                      widget={props.widget}
                      label={props.widgetLabel}
                      value={props.value}
                      options={props.items}
                    />
                  </div>
                );
              }
              if (props.widget === "Calendar") {
                return (
                  <div
                    style={{
                      borderBottom: "0px solid #222222",
                    }}
                  >
                    <CalendarComponent
                      functionName={props.functionName}
                      widget={props.widget}
                      label={props.widgetLabel}
                      value={props.value}
                    />
                  </div>
                );
              }
              if (props.widget === "mic") {
                return (
               
                  <div style={{width:'100%',
                  height: 230,
                  minWidth: 300,
                  marginTop: 35}}>
                  <style>{`
                     .box-mic-widget {
                      position: relative;
                    }
                    
                    .object-mic-widget {
                      display: flex;
                      flex: 0 1 100%;
                      justify-content: center;
                      align-items: center;
                      align-content: stretch;
                    }
                    
                    .outline-mic-widget {
                      width: 60px;
                      height: 60px;
                      border-radius: 50%;
                      border: 8px solid #f06263;
                      animation: pulse ${Math.max(3 - peak / 85, 0.5)}s ease-out infinite;
                      position: absolute;
                    }
                    
                    .button-mic-widget {
                      width: 90px;
                      height: 90px;
                      border-radius: 50%;
                      background: #f06263;
                      box-shadow: 0px 0px 80px #f06263;
                      padding:20px;
                    }
                    
                    @keyframes pulse {
                      0% {
                        transform: scale(0);
                        opacity: 0;
                        border: 65px solid #0b3082;
                      }
                      50% {
                        border: solid #f06263;
                        opacity: 0.8;
                      }
                    
                      90% {
                        transform: scale(3.2);
                        opacity: 0.2;
                        border: 3px solid #f06263;
                      }
                      100% {
                        transform: scale(3.3);
                        opacity: 0;
                        border: 1px solid #f06263;
                      }
                    }
                    
                    #delayed-mic-widget {
                      animation-delay: 1.5s;
                    }
                    
                    #circlein-mic-widget {
                      width: 100px;
                      height: 100px;
                      border-radius: 50%;
                      background: #f06263;
                      box-shadow: 0px -2px 15px #f06263;
                      position: absolute;
                    }
                    
                  `}</style>
                  <div style={{
                    display:'flex', 
                    justifyContent: 'center',
                    alignItems: 'center', 
                     marginTop: 34 }}>
                  <div style={{borderTop:'1px solid #323232'}}></div>
                  <div onClick={async ()=>{window.toggleActiveMicriphone()}} style={{cursor:'pointer', display:'flex', justifyContent:'center', alignItems:"center", marginTop:10, flexDirection:'column'}}>
                  <div className="box-mic-widget">
                      <div className="object-mic-widget" style={{cursor:'pointer'}} >
                          {isActive ? (<div className="outline-mic-widget"></div>):""}
                          {isActive ? (<div className="outline-mic-widget delayed"></div>):""}
                          <div className="button-mic-widget">
                            <svg className="mic-icon-mic-widget" viewBox="0 0 1000 1000" style={{ fill: isActive ? "#F06263" : "white" }}>
                              <path d="M500,683.8c84.6,0,153.1-68.6,153.1-153.1V163.1C653.1,78.6,584.6,10,500,10c-84.6,0-153.1,68.6-153.1,153.1v367.5C346.9,615.2,415.4,683.8,500,683.8z M714.4,438.8v91.9C714.4,649,618.4,745,500,745c-118.4,0-214.4-96-214.4-214.4v-91.9h-61.3v91.9c0,141.9,107.2,258.7,245,273.9v124.2H346.9V990h306.3v-61.3H530.6V804.5c137.8-15.2,245-132.1,245-273.9v-91.9H714.4z" stroke="white" strokeWidth="20"/>
                            </svg>
                        </div>
                      </div>
                  </div>
                  <p style={{
                    fontFamily:'quicksand', 
                    color:'#F06263', 
                    fontSize:20,
                    color: 'rgb(240, 98, 99)',
                    fontSize: 20,
                    cursor:'pointer', marginTop:20}}>{isActive ? 'LISTENING...':'TAP TO SPEAK'}</p>
                  </div>
                  </div>
              </div>
                );
              }
              if(props.widget === "CreateBot") {
                return (
                  <div
                    style={{
                      margin: 0,
                      borderBottom: "3px solid #222222",
                      paddingBottom: 20,
                      display: "block",
                      backgroundColor: "black",
                      maxHeight: '500px',
                      width: '100%',
                      height:'100%'
                    }}
                  >
                <BotWidget/>
                </div>)
              }
              if(props.widget === "BotManager") {
                return (
                  <div
                    style={{
                      margin: 0,
                      borderBottom: "3px solid #222222",
                      paddingBottom: 20,
                      display: "block",
                      backgroundColor: "rgb(28, 28, 28)",
                      maxHeight: '500px',
                      width: '100%',
                      height:'100%',
                      maxWidth: 290
                    }}
                  >
                <BotManager items={props.items} functionName={props.functionName}/>
                </div>)
              }
              if(props.widget === "Switch") {
                return (
                  <div
                    style={{
                      margin: 0,
                      borderBottom: "3px solid #222222",
                      paddingBottom: 20,
                      display: "block",
                      backgroundColor: "black",
                      maxHeight: '500px',
                      width: '100%',
                      height:'100%'
                    }}
                  >
                    <SwitchComponent functionName={props.functionName}/>
                </div>)
              }
              if(props.widget === "Dropdown") {
                return (
                  <div
                    style={{
                      margin: 0,
                      borderBottom: "3px solid #222222",
                      paddingBottom: 20,
                      display: "block",
                      backgroundColor: "black",
                      maxHeight: '500px',
                      width: '100%',
                      height:'100%'
                    }}
                  >
                    <SelectComponent functionName={props.functionName} 
                      widget={props.widget}
                      label={props.widgetLabel}
                      value={props.value}
                      items={props.items} />
                </div>)
              }
              if (props.widgetName === "") {
                return (
                  <div
                    style={{
                      margin: 20,
                      borderBottom: "3px solid #222222",
                      paddingBottom: 20,
                      display: "block",
                      backgroundColor: "black",
                      height: 50,
                      width: 50,
                    }}
                  >
                    {/* <CalendarComponent
                      functionName={widget.functionName}
                      label={widget.label}
                      replaceInstance={widget.replaceInstance}
                      replace={widget.replace}
                      actualInstance={i}
                      value={widget.value}
                      instanceOfWidget={widget.instanceOfWidget}
                      isLocked={widget.isLocked}
                      advancedOptions={WidgetStore.advancedOptions}
                      hideOptions={true}
                    /> */}
                  </div>
                );
              }
            })()}
          </div>
        </div>
      </div>
    </div>
  );
});

export default React.memo(RemixWidgetModal);
