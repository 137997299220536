import { faEnvelope, faTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Avatar from "@material-ui/core/Avatar";
import InputBase from "@material-ui/core/InputBase";
import Radio from "@material-ui/core/Radio";
import Slider from "@material-ui/core/Slider";
import Switch from "@material-ui/core/Switch";
import TextField from "@material-ui/core/TextField";
import { alpha, fade, makeStyles, withStyles } from "@material-ui/core/styles";
import AWS from "aws-sdk";
import * as S3 from "aws-sdk/clients/s3";
import clsx from "clsx";
import "cropperjs/dist/cropper.css";
import { useFormik } from "formik";
import Hashids from "hashids";
import { createMemoryHistory } from "history";
import {
  default as React,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import Cropper from "react-cropper";
import { components } from "react-select";
import { v4 as uuid } from "uuid";
import * as Yup from "yup";
import { AuthenticationStoreContext } from "../../store/AuthenticationStore.js";
import { EditorStoreContext } from "../../store/EditorStore.js";
import { firebase } from "../firebase/firebase";
import "../styles/firebaseui-styling.global.css"; // Import globally. Not with CSS modules.
import { AuthContext } from "./AuthContext";
import { Config } from "./config/Config";
import ReCAPTCHA from "react-google-recaptcha";
import PhoneSignIn from "../Home/PhoneSignIn.js"
import PhoneInput from 'react-phone-number-input';
import MaskedInput from 'react-text-mask';
import SigninM from "./SigninM.js";
import { db } from '../firebase/firebase'; // Adjust path as necessary

import { faCheck } from "@fortawesome/pro-solid-svg-icons";
let cooldownActive = false; // Global flag for cooldown

let firestore = firebase.firestore();

let profileLoaded = false;
let email;
let alreadyLoaded = false;

AWS.config.update({
  region: "us-west-2",
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId: "us-west-2:5df2511a-5595-416c-b148-aba28893c3f3",
  }),
});
const s3 = new S3();
const customStyles = {
  indicatorsContainer: (provided, state) => ({
    backgroundColor: "#2d2a2b",
    borderRadius: 4,
    color: "white",
  }),

  control: (_, { selectProps: { width, backgroundColor, height } }) => ({
    height: 35,
    backgroundColor: "#2d2a2b",
    fontSize: 12,
    display: "flex",
    justifyContent: "space-between",
    borderRadius: 30,
    padding: "0px",
    fontFamily: "quicksand",
    color: "white",
    minHeight: 3,
    //width: "calc(100% - 50px)",

    // overflow: "hidden",
  }),

  dropdownIndicator: (base) => ({
    // ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  clearIndicator: (base) => ({
    // ...base,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  valueContainer: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      backgroundColor: "#2d2a2b",
      // height: 30,
      fontSize: 20,

      borderRadius: 30,
      padding: "0px",
      color: "white",
      justifyContent: "space-between",
      alignItems: "center",
      display: "flex",
      fontFamily: "quicksand",
      padding: "0px 10px",
      // width: "calc(100% - 50px)",
    };
  },
  menu: (provided, state) => {
    return {
      // ...provided,
      fontFamily: "quicksand",
      backgroundColor: "#2d2a2b",
      overflow: "hidden",
      fontSize: 20,
      // position: "absolute",
      top: "22px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      color: "white",
      // width: "calc(100% - 50px)",
      // color: state.selectProps.menuColor,
    };
  },
  placeholder: (provided, state) => {
    return {
      fontFamily: "quicksand",
      color: "white",
      alignItems: "auto",
      justifyContent: "space-between",
      display: "flex",
      fontSize: 20,
      // width: "calc(100% - 50px)",
    };
  },
  dropdownIndicator: (provided, state) => {
    return {
      // marginTop: 5,
      fontSize: "10px",
      height: 90,
      // width: "calc(100% - 50px)",
    };
  },
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = "opacity 300ms";

    return {
      // height: 30,
      fontSize: 20,

      padding: "0px",
      color: "white",
      fontFamily: "quicksand",
      // width: "calc(100% - 50px)",
    };
  },
  option: (base) => ({
    ...base,
    height: "100%",
    width: "calc(100vw - 50px)",
  }),
};
// const useStyles = makeStyles((theme) => ({
//   root: {
//     display: "flex",
//     flexWrap: "wrap",
//     justifyContent: "space-around",
//     overflow: "hidden",
//     // backgroundColor: theme.palette.background.paper,
//   },
//   imageList: {
//     width: 500,
//     height: 450,
//   },
//   icon: {
//     color: "rgba(255, 255, 255, 0.54)",
//   },
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 244,
//   },
//   selectEmpty: {
//     marginTop: theme.spacing(2),
//   },
// }));
const BootstrapInput = withStyles((theme) => ({
  root: {
    "label + &": {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: "relative",
    backgroundColor: "#222222",
    border: "1px solid #222222",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: "red",
    },
  },
}))(InputBase);
const radioStyles = makeStyles({
  root: {
    "&:hover": {
      backgroundColor: "transparent",
      color: "white",
    },
  },
  icon: {
    borderRadius: "50%",
    border: "4px solid #2d2a2b",
    width: 30,
    height: 30,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    backgroundColor: "#2d2a2b",
    color: "white",
    // backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    // '$root.Mui-focusVisible &': {
    // outline: '1px auto rgba(19,124,189,.6)',
    //   outlineOffset: 2,
    // },
  },
  checkedIcon: {
    backgroundColor: "#4FD975",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 30,
      height: 30,
      // backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
      content: '""',
      color: "white",
    },
    "input:hover ~ &": {
      backgroundColor: "#4FD975",
      color: "white",
    },
  },
});
const Placeholder = (props) => {
  return <components.Placeholder {...props} />;
};
const CaretDownIcon = () => {
  return (
    <FontAwesomeIcon
      icon={faTriangle}
      color="#A962F1"
      style={{
        display: "block",
        position: "absolute",
        right: "10px",
        top: "10px",
        transform: "rotate(180deg)",
        fontSize: "15px",
      }}
    />
  );
};
const MenuItem = ({ text, selected }) => {
  return (
    <div
      className={`menu-item ${selected ? "active" : ""}`}
      style={{ fontSize: 14, fontWeight: "bold" }}
    >
      {text}
    </div>
  );
};

export const Menu = (list, selected) =>
  list.map((el) => {
    const { name } = el;

    return <MenuItem text={name} key={name} selected={selected} />;
  });
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};
// Inspired by blueprintjs
function StyledRadio(props) {
  const classes = radioStyles();

  return (
    <Radio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}
const PrettoSlider = withStyles({
  root: {
    color: "#A962F1",
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus, &:hover, &$active": {
      boxShadow: "inherit",
    },
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)",
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const CssTextField = withStyles({
  typography: {
    fontFamily: "quicksand",
  },
  root: {
    "& label.Mui-focused": {
      color: "green",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "green",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#222222",
        borderWidth: 4,
      },
      "&:hover fieldset": {
        borderColor: "#6371F6",
        borderWidth: 4,
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6371F6",
      },
    },
  },
})(TextField);

const IOSSwitch = withStyles((theme) => ({
  root: {
    width: 50,
    height: 27,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    "&$checked": {
      transform: "translateX(24px)",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#3f3f3e",
        // opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "white",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    height: 27,
    borderRadius: 27 / 2,
    border: `1px solid #3f3f3e`,
    backgroundColor: "#3f3f3e",
    opacity: 1,
    // transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #545454",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#2C2C2C",
    color: "white",
    height: 64,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#2C2C2C",
    },
    "&$focused": {
      backgroundColor: "#2C2C2C",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: "#40ffe8",
    },
  },
  focused: {},
}));

const bioStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #545454",
    overflow: "hidden",
    borderRadius: 4,
    backgroundColor: "#2C2C2C",
    color: "white",
    height: 169,
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#2C2C2C",
    },
    "&$focused": {
      backgroundColor: "#2C2C2C",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: "#40ffe8",
    },
  },
  focused: {},
}));

const signInStyles = makeStyles((theme) => ({
  root: {
    border: "0px solid #545454",
    overflow: "hidden",
    borderRadius: 15,
    backgroundColor: "#2D2A2B",
    color: "white",
    height: 37,
    width: "100%",
    paddingLeft: 10,
  },
  focused: {},
}));

function NameTextField(props) {
  const classes = useStyles();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}

function SignInText(props) {
  const classes = signInStyles();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}

function UserTextField(props) {
  const classes = useStyles();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}

function BioTextField(props) {
  const classes = bioStyles();

  return (
    <TextField InputProps={{ classes, disableUnderline: true }} {...props} />
  );
}
let fullnameg;
const debounce = (func, delay) => {
  let timeoutId;
  return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => func(...args), delay);
  };
};
let isCooldownActive = false; // To track cooldown status

const startCooldown = (cooldownTime) => {
    isCooldownActive = true;
    setTimeout(() => {
        isCooldownActive = false;
    }, cooldownTime);
};

const LoginScreen = (props) => {
  const classes = useStyles();

  const [file, setFiles] = useState("");
  const [fileName, setFileName] = useState("");
  const [preview, setPreview] = useState("");
  const [cropResult, setCropResults] = useState("");
  const [avatar, setAvatar] = useState("");
  const [isUploading, setIsUploading] = useState("");
  const [progress, setProgress] = useState(0);
  const [avatarURL, setAvatarURL] = useState("");
  const [postedPicURL, setPostedPicURL] = useState("");
  const [fullname, setFullname] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [username, setUsername] = useState("");
  const [nameExists, setNameExists] = useState(false);
  const [urlPhoto, setUrlPhoto] = useState("");
  const [showFullName, setShowFullName] = useState(true);
  const [fullNameHidden, setFullNameHidden] = useState("");
  const AuthenticationStore = useContext(AuthenticationStoreContext);
  const [login, setLogin] = useState(false);
  const [emailVerification, setEmailVerification] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");
  const [usernameProfile, setUsernameProfile] = useState("[username]");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [firebaseComponentShow, setFirebaseComponentShare] = useState(false);
  const [sendPasswordBtn, setSendPasswordBtn] = useState(true);
  const [isPasswordRecover, setIsPasswordRecover] = useState(false);
  const [sendVerifyButton, setSendVerifyButton] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState("");
  const [showUpWithEmail, setShowUpWithEmail] = useState(false);
  const [showUpWithPhone, setShowUpWithPhone] = useState(false);
  const EditorStore = useContext(EditorStoreContext);
  const history = createMemoryHistory();
  const { currentUser } = useContext(AuthContext);
  const [isVerified, setIsVerified] = useState(false);
  const [value, setValue] = useState();
  const [hideOptions, setHideOptions] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showAuthMain, setShowAuthMain] = useState(true);
  const [emailCheck, setEmailCheck] = useState("");
  const [countdown, setCountdown] = useState(0);
  const [errorMessagePasswordReset, setErrorMessagePasswordReset] = useState("");
  const [inviteOnlyMode, setInviteOnlyMode] = useState(true);
  const [inviteErrorMsg, setInviteErrorMsg] = useState("");
  const [requestAccepted, setRequestAccepted] = useState(false);
  const [isInviteLoading, setIsInviteLoading] = useState(false);
  const [inviteEmail, setInviteEmail] = useState("");
  const [inviteInfo, setInviteInfo] = useState("");
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
  const [inviteFirstName, setInviteFirstName] = useState("");
  const [inviteLastName, setInviteLastName] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const emailInput = useRef(null);


  const modalRef = useRef(null); // Reference to the scrollable modal
  const scrollbarRef = useRef(null); // Reference to the custom scrollbar
  const [isDragging, setIsDragging] = useState(false); // Track dragging state
  const [dragStartY, setDragStartY] = useState(0); // Track initial drag position
  const [scrollStartTop, setScrollStartTop] = useState(0); // Track initial scroll position'=
  const [showInviteForm, setShowInviteForm] = useState(false);
  const recaptchaRef = useRef(null); // Reference to the reCAPTCHA instance
  const [cancelDisabled, setCancelDisabled] = useState(false);
  useEffect(() => {
    const modal = modalRef.current;
    const customScrollbar = scrollbarRef.current;
  
    if (!modal || !customScrollbar) return;
  
    const calculateScrollbarHeight = () => {
      return window.innerHeight - 500; // Equivalent to calc(100vh - 50px)
    };
  
    const updateScrollbar = () => {
      const scrollHeight = modal.scrollHeight; // Total scrollable content height
      const clientHeight = modal.clientHeight; // Visible height of the modal
      const scrollTop = modal.scrollTop; // Current scroll position
    
      // Compute the height of the custom scrollbar
      const scrollbarHeight = (clientHeight / scrollHeight) * (window.innerHeight - 170); // Use customHeight
      const scrollbarTop = (scrollTop / (scrollHeight - clientHeight)) * (window.innerHeight - 170 - scrollbarHeight);
    
      // Apply height and position
      customScrollbar.style.height = `${scrollbarHeight}px`;
      customScrollbar.style.transform = `translateY(${scrollbarTop}px)`;
      customScrollbar.style.visibility = scrollHeight > clientHeight ? "visible" : "hidden";
    
      // Debugging logs
      console.log("Viewport Height:", window.innerHeight);
      console.log("Computed Scrollbar Height:", scrollbarHeight);
      console.log("Scrollbar Top:", scrollbarTop);
      console.log("Modal Scroll Height:", scrollHeight);
      console.log("Client Height:", clientHeight);
    };
    
    
  
    const handleDragStart = (e) => {
      e.preventDefault();
      setIsDragging(true);
      setDragStartY(e.clientY || e.touches[0].clientY); // For touch events
      const currentScrollbarTop = parseFloat(
        customScrollbar.style.transform.replace("translateY(", "").replace("px)", "")
      ) || 0;
      setScrollStartTop(currentScrollbarTop);
    };
  
    const handleDragMove = (e) => {
      if (!isDragging) return;
    
      const currentY = e.clientY || e.touches[0].clientY; // Mouse or touch position
      const deltaY = currentY - dragStartY; // Distance dragged
    
      const scrollHeight = modal.scrollHeight;
      const clientHeight = modal.clientHeight;
    
      // Maximum scrollable content
      const maxScrollTop = scrollHeight - clientHeight;
    
      // Maximum scrollbar position
      const scrollbarHeight = (clientHeight / scrollHeight) * (window.innerHeight - 170);
      const maxScrollbarTop = window.innerHeight - 170 - scrollbarHeight;
    
      // Calculate new scrollbar position (clamped within bounds)
      const newScrollbarTop = Math.min(Math.max(0, scrollStartTop + deltaY), maxScrollbarTop);
    
      // Calculate and apply proportional scrollTop for modal content
      modal.scrollTop = (newScrollbarTop / maxScrollbarTop) * maxScrollTop;
    
      // Update the custom scrollbar's position
      customScrollbar.style.transform = `translateY(${newScrollbarTop}px)`;
    };
    
  
    const handleDragEnd = () => {
      setIsDragging(false);
    };
  
    // Attach scroll listener to sync custom scrollbar
    modal.addEventListener("scroll", updateScrollbar);
  
    // Attach drag listeners to the custom scrollbar
    customScrollbar.addEventListener("mousedown", handleDragStart);
    customScrollbar.addEventListener("touchstart", handleDragStart);
    window.addEventListener("mousemove", handleDragMove);
    window.addEventListener("touchmove", handleDragMove);
    window.addEventListener("mouseup", handleDragEnd);
    window.addEventListener("touchend", handleDragEnd);
  
    // Initialize scrollbar on mount
    updateScrollbar();
  
    // Adjust custom scrollbar height on window resize
    const handleResize = () => {
      const customHeight = window.innerHeight - 50; // Equivalent to calc(100vh - 50px)
      customScrollbar.style.height = `${customHeight}px`;
      updateScrollbar(); // Ensure the scrollbar position updates on resize
    };
    
    window.addEventListener("resize", handleResize);
  
    return () => {
      modal.removeEventListener("scroll", updateScrollbar);
      customScrollbar.removeEventListener("mousedown", handleDragStart);
      customScrollbar.removeEventListener("touchstart", handleDragStart);
      window.removeEventListener("mousemove", handleDragMove);
      window.removeEventListener("touchmove", handleDragMove);
      window.removeEventListener("mouseup", handleDragEnd);
      window.removeEventListener("touchend", handleDragEnd);
      window.removeEventListener("resize", handleResize);
    };
  }, [isDragging, dragStartY, scrollStartTop]);
  
  const sendEmailToAdmins = async () => {
    try {
      // Customize the subject and HTML as needed
      const requestBody = {
        subject: "Flowroom Invite Request Notification", 
        html: `
          <h2>Dear Admins,</h2>
          <p>A new invite request has been made. Please log in and access the admin dashboard to review and accept the invite.</p>
          <p>Best regards,<br/>Flowroom Team</p>
        `,
      };

      const response = await fetch("https://us-central1-flowroom-fd862.cloudfunctions.net/sendEmailToAdmins", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      const data = await response.json();

      if (response.ok) {
        console.log("Email sent successfully:", data);
        // Handle success (e.g., display a success message to the user)
      } else {
        console.error("Error sending email:", data);
        // Handle error (e.g., display an error message to the user)
      }
    } catch (err) {
      console.error("Request failed:", err);
      // Handle fetch error
    }
  };


  const handleSendVerificationEmail = async (email, fullname) => {
    try {
      const response = await fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/sendCustomInviteEmailMessage",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: email,
            displayName: fullname,
          }),
        }
      );
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || "Failed to send verification email.");
      }
  
      const result = await response.json();
      console.log(result.message); // Log success message
    } catch (error) {
      console.error("Error sending email:", error.message);
  
      // Handle specific errors like rate-limiting
      if (error.message.includes("TOO_MANY_ATTEMPTS_TRY_LATER")) {
        throw new Error("Too many attempts to send verification emails. Please wait a moment.");
      }
  
      throw error; // Rethrow other errors for parent function to handle
    }
  };
  
  
  const saveInviteRequest = async () => {
    if (cooldownActive) {
      setErrorMessage("You are making requests too quickly. Please wait a moment before trying again.");
      return;
    }
  
    try {
      const inviteEmail = document.getElementById("inviteEmail").value.trim().toLowerCase();
      const inviteInfo = document.getElementById("inviteInfo").value.trim();
      const inviteFirstName = document.getElementById("inviteFirstName").value.trim();
      const inviteLastName = document.getElementById("inviteLastName").value.trim();

      setErrorMessage("");
  

      if (!inviteFirstName) {
        setErrorMessage("You cannot submit empty fields.");
        return;
      }

      if (!inviteLastName) {
        setErrorMessage("You cannot submit empty fields.");
        return;
      }


      if (!inviteEmail) {
        setErrorMessage("The email field is empty.");
        return;
      }
  
      if (!inviteInfo) {
        setErrorMessage("You cannot submit empty fields.");
        return;
      }
  
      setIsInviteLoading(true);
      setIsDisabled(true);
      setCancelDisabled(true);
      if(cancelDisabled) {
        setErrorMessage("Your invite request is being processed and can’t be canceled right now.");
      }
      const invitesRef = db.collection("invites");
      const inviteRequestsRef = db.collection("inviteRequests");
  
      // Check if there is an existing invite with the provided email
      const existingInviteSnapshot = await invitesRef
        .where("redeemedByEmail", "==", inviteEmail)
        .get();
  
      if (!existingInviteSnapshot.empty) {
        const existingInvite = existingInviteSnapshot.docs[0].data();
        if (existingInvite.inviteAccepted) {
          setErrorMessage("The invite request for this email has already been accepted. You can now select 'Cancel' then 'Continue with Email' to proceed. If you haven’t signed up yet, you’ll be guided to the sign-up screen. If you’ve already signed up, you’ll be directed to log in.");
          setIsInviteLoading(false);
          setIsDisabled(false);
          setCancelDisabled(false);


          return;
        } else {
          setErrorMessage("This email is already on the waiting list for an invite. Once your request is accepted by our team, you will receive an email with instructions on how to proceed.");
          setIsInviteLoading(false);
          setIsDisabled(false);
          setCancelDisabled(false);

          return;
        }
      }
  
      // Check for existing invite requests and enforce rate-limiting
      const existingRequestSnapshot = await inviteRequestsRef
        .where("inviteEmail", "==", inviteEmail)
        .get();
  
      if (!existingRequestSnapshot.empty) {
        const existingRequest = existingRequestSnapshot.docs[0].data();
        const lastRequestTime = existingRequest.createdAt?.toDate();
        const now = new Date();
  
        // Rate-limiting for requests: Allow only one request per minute
        if (lastRequestTime && now - lastRequestTime < 60 * 1000) {
          setErrorMessage("You are making requests too quickly. Please wait a moment before trying again.");
          setIsInviteLoading(false);
          setIsDisabled(false);
          setCancelDisabled(false);

          return;
        }
  
        setErrorMessage("This email is already on the waiting list for an invite. Once your request is accepted by our team, you will receive an email with instructions on how to proceed.");
        setIsInviteLoading(false);
        setIsDisabled(false);
        setCancelDisabled(false);

        return;
      }
  
      try {
        // Email Rate-Limiting
        const emailRateLimitRef = db.collection("emailRateLimit").doc(inviteEmail);
        const emailRateLimitDoc = await emailRateLimitRef.get();
        const now = new Date();
  
        if (emailRateLimitDoc.exists) {
          const lastEmailTime = emailRateLimitDoc.data()?.lastSent?.toDate();
  
          // Enforce email rate-limiting (1 email per minute)
          if (lastEmailTime && now - lastEmailTime < 60 * 1000) {
            setErrorMessage("You are sending emails too quickly. Please wait a moment before trying again.");
            setIsInviteLoading(false);
            setIsDisabled(false);
            setCancelDisabled(false);

            return;
          }
        }
  
        let fullname = inviteFirstName + " " + inviteLastName;
  
        // Send verification email
        await handleSendVerificationEmail(inviteEmail, fullname);
  
        // Update email rate-limit timestamp
        await emailRateLimitRef.set({ lastSent: firebase.firestore.FieldValue.serverTimestamp() }, { merge: true });
  
        // Add invite request to Firestore only if email was successfully sent
        const newInviteRequestRef = await inviteRequestsRef.add({
          inviteEmail: inviteEmail,
          inviteInfo: inviteInfo,
          createdAt: new Date(),
          fullname: fullname
        });
  
        if (newInviteRequestRef.id) {
          await sendEmailToAdmins();
          console.log("Invite request saved successfully.");
          setRequestAccepted(true);
          setErrorMessage("");
          setIsDisabled(false);
          setCancelDisabled(false);

        }
      } catch (emailError) {
        console.error("Error sending verification email:", emailError);
  
        if (emailError.message.includes("Too many attempts")) {
          setErrorMessage("Too many attempts to send emails. Please try again later.");
        } else {
          setErrorMessage("Failed to send verification email. Please try again.");
        }
  
        setIsInviteLoading(false);
        setIsDisabled(false);
        setCancelDisabled(false);

        return; // Do not proceed further if email sending fails
      }
  
      // Start the client-side cooldown
      cooldownActive = true;
      setTimeout(() => {
        cooldownActive = false; // Reset cooldown after 1 minute
      }, 60000); // 1 minute cooldown
  
      setIsInviteLoading(false);
      setIsDisabled(false);
      setCancelDisabled(false);

    } catch (error) {
      console.error("Error saving invite request:", error);
      setErrorMessage("An error occurred while processing your request. Please try again later.");
      setIsInviteLoading(false);
      setIsDisabled(false);
      setCancelDisabled(false);

    }
  };


  const handleRecaptchaVerify = async (value) => {
   if (value) {
      console.log("reCAPTCHA token:", value);
      setIsVerified(true); // Verification successful
      setErrorMessage('');
      await saveInviteRequest()
    } else {
      console.error("reCAPTCHA verification failed.");
      setIsVerified(false);
    }
  };

  // Function to execute reCAPTCHA and handle form submission
  const handleInviteSubmit = async (event) => {
    event.preventDefault();

    if (recaptchaRef.current) {
      recaptchaRef.current.execute(); // Trigger reCAPTCHA
    } else {
      console.error("reCAPTCHA not properly initialized.");
    }

    // Check verification status after reCAPTCHA executes
    if (isVerified) {
      console.log("Submit form data...");
      // Add logic to handle invite submission
    } else {
      if(requestAccepted == true) {
      setErrorMessage("Please verify that you are not a robot.");
      }
    }
  };


  const checkTheUserInviteStatus = async (email) => {
    try {
        const trimmedEmail = email.trim().toLowerCase();

        // Check "invites" collection first
        const usersRef = db.collection("invites");
        const inviteQuerySnapshot = await usersRef
            .where("redeemedByEmail", "==", trimmedEmail)
            .where("inviteAccepted", "==", true)
            .get();

        if (!inviteQuerySnapshot.empty) {
            console.log("Invite found and accepted.");
            setInviteOnlyMode(false);
            return true;
        }

        // If no document found, check "inviteRequests" collection
        const inviteRequestsRef = db.collection("inviteRequests");
        const inviteRequestQuerySnapshot = await inviteRequestsRef
            .where("inviteEmail", "==", trimmedEmail)
            .get();

        if (!inviteRequestQuerySnapshot.empty) {
            console.log("This email is on the waiting list. Once your invite request has been accepted, you will be able to continue.");
            setErrorMessage("This email is on the waiting list. Once your invite request has been accepted, you will be able to continue.");
            return false;
        }

        // No document found in either collection
        console.log("No invite or invite request found for this email.");
        setErrorMessage("No invite or invite request found for this email. Please check your email or submit a request.");
        return false;
    } catch (error) {
        console.error("Error fetching user data: ", error);
        setErrorMessage("An error occurred while checking invite status. Please try again later.");
        return false;
    }
};


  const handleRecaptcha = (value) => {
    // If value is null, recaptcha expired
    if(value) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  };


  const handleRecaptchaInviteInvisible = (token) => {
    if (token) {
      console.log("reCAPTCHA verified with token:", token);
      setIsVerified(true); // Update the verification state
    } else {
      console.warn("reCAPTCHA verification expired or failed.");
      setIsVerified(false);
    }
  };

  const handleRecaptchaInvite = (value) => {
    // If value is null, recaptcha expired
    if(value) {
      setIsVerified(true);
    } else {
      setIsVerified(false);
    }
  };


  const handleInputChange = (event) => {
    // Update the emailCheck state with the input's current value
    setEmailCheck(event.target.value);
  };

  const checkEmailStatus = () => {
    let email = document.getElementById('email-check').value.trim().toLowerCase();
    firebase.auth().fetchSignInMethodsForEmail(email)
  .then((signInMethods) => {
    // This will return a list of sign-in methods for the given email address.
    // If the list is empty, the email has not been registered.
    if (signInMethods.length === 0) {
      console.log('Email not found, can sign up.');
      setShowUpWithEmail(false);
      setShowSignUp(true);
      setErrorMessage("");
    } else {
      setShowUpWithEmail(false);
      setShowLogin(true);
      setErrorMessage("");
      console.log('Email found, can sign in. Sign-in methods: ' + signInMethods.join(', '));
    }
  })
  .catch((error) => {
    console.error(error);
    setErrorMessage(error.message)
    // Handle errors here, such as email not found, invalid email, etc.
  });

  }

  const handleSubmit = (e) => {
    if(isVerified) {
      // Proceed with form submission or other logic
      console.log("Form submitted");
      let email = document
      .getElementById("email")
      .value.toLowerCase()
      .trim().toLowerCase();
    let password = document
      .getElementById("password")
      .value.trim();
    setErrorMessage("");
    setPasswordErrorMessage("");
    localStorage.setItem("emailUsing", email);
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        // Signed in
        var user = userCredential.user;
        // ...
        // window.location.replace("/featured");
      })
      .catch((error) => {
        if (error.code === "auth/user-not-found") {
          setErrorMessage(
            "The email you entered does not exist."
          );
        } else if (
          error.code === "auth/email-already-in-use"
        ) {
          setErrorMessage(
            "The email you entered is already in use."
          );
        } else if (
          error.code === "auth/invalid-email"
        ) {
          setErrorMessage(
            "The email you entered was badly formatted"
          );
        } else if (
          error.code === "auth/wrong-password"
        ) {
          setPasswordErrorMessage(
            "The password you entered was incorrect"
          );
        } else if (
          error.code === "auth/too-many-requests"
        ) {
          setErrorMessage(
            "You made too many requests! Please wait a few minutes and try again."
          );
        }
        firebase
          .auth()
          .fetchSignInMethodsForEmail(email)
          .then(function (providers) {
            console.log("providers", providers);
            if (providers[0] === "google.com") {
              setErrorMessage(
                'You already have an account. Please select "Cancel" and sign into your account with Google.'
              );
            }
            if (providers[0] === "github.com") {
              setErrorMessage(
                "You already have an account. Please sign into your account with Github."
              );
            }

            // The returned 'providers' is a list of the available providers
            // linked to the email address. Please refer to the guide for a more
            // complete explanation on how to recover from this error.
          })
          .catch((error) => {
            if (
              error.code ===
              "auth/email-already-in-use"
            ) {
              setErrorMessage(
                "The email you entered is already in use."
              );
            } else if (
              error.code === "auth/invalid-email"
            ) {
              setErrorMessage(
                "The email you entered was badly formatted"
              );
            } else if (
              error.code === "auth/wrong-password"
            ) {
              setPasswordErrorMessage(
                "The password you entered was incorrect"
              );
            } else if (
              error.code === "auth/too-many-requests"
            ) {
              setErrorMessage(
                "You made too many requests! Please wait a few minutes and try again."
              );
            }
          });
      });

    // firebase
    //   .auth()
    //   .createUserWithEmailAndPassword(email, password)o
    //     // Signed in
    //     const user = userCredential.user;

    //     firebase
    //       .auth()
    //       .currentUser.sendEmailVerification();
    //   })
    //   .catch((error) => {
    //     // document.querySelector(
    //     //   ".error.reg_error"
    //     // ).innerHTML = error.message;
    //   });
    } else {
      if(!inviteOnlyMode) {
      setErrorMessage("Please verify that you are a human!");
      }
    }
  };

  useEffect(() => {
    let interval;
    if (countdown > 0) {
      interval = setInterval(() => {
        setCountdown(countdown - 1);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [countdown]);

  const handleSendClick = () => {
    const email = document.getElementById("email").value.trim().toLowerCase();
    firebase
      .auth()
      .sendPasswordResetEmail(email)
      .then(() => {
        setSendPasswordBtn(false);
        setCountdown(300);
      })
      .catch((error) => {
        setErrorMessagePasswordReset(error.message);
      });
  };

  const uiConfig = {
    // Popup signin flow rather than redirect flow.
    signInFlow: "popup",
    // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
    // We will display Google and Facebook as auth providers.
    signInOptions: [
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.GithubAuthProvider.PROVIDER_ID,
    ],

    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false,

      // Avoid redirects after sign-in.
    },

    fullLabel: "",
  };

  const cropper = useRef("cropper");
  // Configure FirebaseUI.
  const localData = JSON.parse(localStorage.getItem("ProfilePhoto"));
  //alert(localData.profilePhoto);
  let fullname_ = fullnameg;

  let getFullName = () => {
    let clear = setInterval(() => {
      fullname_ = fullnameg;
    }, 300);
    return fullname_ !== null ? fullname_ : "";
  };
  const formik = useFormik({
    initialValues: {
      username: "",
      bio: "",
      fullname: "",
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(3, "Username has to be 3 characters or longer!")
        .max(15, "Username can not be longer than 15 characters!")
        .matches(
          /^[a-zA-Z0-9]{3,15}$/g,
          "Username can not have any spaces or special characters!"
        )
        .required("Username is required!"),
      fullname: Yup.string().required("Full name is required!"),
    }),
    onSubmit: (values) => {
      // console.log("[UserModal] onSubmit", JSON.stringify(values, null, 2));
      let username = formik.values.username;
      username = username.toLowerCase();
      username = username.replace(/\s/g, "");
      let fullname =
        showFullName === false ? fullNameHidden : formik.values.fullname;
      let bio = formik.values.bio;
      let userData = {};
      if (username !== "") {
        userData.username = username.trim();
      }
      if (fullname !== "") {
        userData.fullname = fullname;
      }
      if (bio !== "") {
        userData.bio = bio;
      }
      if (urlPhoto && urlPhoto !== "") {
        userData.urlPhoto = urlPhoto;
      }
      if (username === "") {
        document.getElementById("username").focus();
      } else {
        firebase
          .firestore()
          .collection("users")
          .where("username", "==", username)
          .get()
          .then((snapshot) => {
            if (snapshot.docs.length > 0) {
              let data = snapshot.docs[0].data();
              if (
                data.username &&
                data.username == username &&
                data.userId !== firebase.auth().currentUser.uid
              ) {
                setNameExists(true);
                return;
              }
            }
            firebase
              .firestore()
              .collection("users")
              .doc(firebase.auth().currentUser.uid)
              .set(userData, { merge: true })
              .then(() => {
                firebase
                  .auth()
                  .currentUser.updateProfile({
                    displayName: username.trim(),
                    photoURL: urlPhoto,
                    fullname: fullname,
                    displayOnProfile: EditorStore.displayOnProfile,
                    userRole: EditorStore.userRole,
                    pronouns: EditorStore.pronouns,
                  })
                  .then(() => {
                    localStorage.setItem(
                      "user",
                      JSON.stringify({
                        displayName: username.trim(),
                        photoURL: urlPhoto,
                        fullname: fullname,
                        displayOnProfile: EditorStore.displayOnProfile,
                        userRole: EditorStore.userRole,
                        pronouns: EditorStore.pronouns,
                      })
                    );
                               

                    AuthenticationStore.setShowModal(false);
                    // AuthenticationStore.setIsLoggedIn((prev) => !prev);
                    if (alreadyLoaded == false) {
                      alreadyLoaded = true;
                      window.location.replace("/");
                    }

                    if (username !== "") {
                      AuthenticationStore.setDisplayName(username);
                    }

                    if (fullname !== "") {
                      AuthenticationStore.setFullname(fullname);
                    }

                    if (bio !== "") {
                      AuthenticationStore.setBio(bio);
                    }

                    if (urlPhoto !== "") {
                      AuthenticationStore.setPhotoURL(urlPhoto);
                    }
                    AuthenticationStore.setShowUserModal(false);
                    AuthenticationStore.setIsProfileLoaded(false);
                  })
                  .catch((error) => {
                    // console.log(error);
                  });
              })
              .catch((error) => {
                //console.log(error);
              });
          });
      }
    },
  });

  const _crop = () => {
    // image in dataUrl
    let imageURL = cropper.getCroppedCanvas().toDataURL();
  };

  const handleUploadStart = () => {
    setIsUploading(true);
    setProgress(0);
  };

  const handleProgress = (progress) => {
    setProgress(progress);
  };

  const handleUploadError = (error) => {
    setIsUploading(false);
    console.error(error);
  };

  const handleUploadSuccess = (filename) => {
    let usernamef = document.getElementById("username").value;
    setAvatar(filename);
    setProgress(100);
    setIsUploading(false);
    firebase
      .storage()
      .ref("images")
      .child(filename)
      .getDownloadURL()
      .then((url) => {
        setPostedPicURL(url);
        props.userStore({ username: usernamef, pic: url });
        AuthenticationStore.setPhotoURL(url);
      });
  };

  function linkAccounts(emailToLink) {
    const user = firebase.auth().currentUser;
  
    if (!user) {
      console.error("No user is currently signed in.");
      return;
    }

  
    // Retrieve the existing user's sign-in methods to determine the next steps
    firebase.auth().fetchSignInMethodsForEmail(emailToLink)
      .then((methods) => {
        if (methods.includes('password')) {
          // The user has an email/password account.
          // Here, you would securely prompt the user to enter their password.
          // Note: Implement a secure way to collect the user's password, considering your application's UX.
          const password = prompt("Please enter your password for the existing account:");
          if (!password) {
            console.log("Password input was cancelled or empty.");
            return;
          }
          const credential = firebase.auth.EmailAuthProvider.credential(emailToLink, password);
          
          // Now link the accounts.
          user.linkWithCredential(credential).then(() => {
            console.log("Accounts linked successfully.");
            // Handle post-linking logic here, such as updating Firestore or UI.
          }).catch((error) => {
            console.error("Failed to link accounts:", error);
          });
        } else if (methods.length === 0) {
          // No existing account with this email. Handle accordingly, possibly by creating a new account.
          console.log("No existing account found with this email. Consider creating a new account or prompting the user for different credentials.");
        }
        // Add handling for other methods if necessary.
      }).catch((error) => {
        console.error("Error fetching sign-in methods for email:", error);
      });
  }
  
  const checkUserInviteStatus = async (user) => {
    try {
        const trimmedEmail = user.email.trim();
        const usersRef = db.collection("users");

        const querySnapshot = await usersRef
            .where("email", "==", trimmedEmail)
            .where("inviteAccepted", "==", true)
            .get();

        if (querySnapshot.empty) {
            console.log("You must submit an invite request to continue");
            return;
        }

        console.log("Invite found and accepted.");
    } catch (error) {
        console.error("Error fetching user data: ", error);
    }
};


  const removePhoto = () => {
    setPostedPicURL("");
    // this.props.userStore({ pic: '' });
  };

  const handleChange = (event) => {
    setUsername(event.target.value);
  };

  const uploadFile = (file) => {
    let hashids = new Hashids(uuid(), 6);
    let fileName = hashids.encode(1, 2, 3) + ".png";

    let params = {
      Bucket: Config.HOST_S3,
      Key: "uploads/" + fileName,
      ContentType: file.type,
      Body: file,
    };

    s3.putObject(params, function (err, data) {
      if (err) {
        //console.log("error :", err);
      } else {
        //console.log("data :", data);
        setUrlPhoto(`https://${Config.HOST_CLOUDFRONT}/uploads/${fileName}`);
      }
    });
  };


  const sendEmail = async () => {
    try {
      const response = await fetch(
        "https://us-central1-flowroom-fd862.cloudfunctions.net/sendInviteOnlyEmail",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email: "matthew.cremins@gmail.com",
            name: "Matt Cremins",
          }),
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const result = await response.json();
      console.log(result.message);
    } catch (error) {
      console.error("Error occurred:", error);
    }
  };
  
  


  const showPhotoPreview = (file) => {
    setUrlPhoto(window.URL.createObjectURL(file));
  };
  const keyDownHandler = useCallback((KeyboardEvent) => {
    document.addEventListener("keydown", function (event) {
      if (event.keyCode === 9 && event.target.nodeName === "INPUT") {
        var form = event.target.form;
        var index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 1].focus();
        event.preventDefault();
      }
    });
  }, []);


  useEffect(() => {
    setLogin(AuthenticationStore.signInUpBtnState);
    window.onkeypress = function (e) {
      if (e.which == 13) {
        e.preventDefault();
        var inputs = document.getElementsByClassName("input");
        for (var i = 0; i < inputs.length; i++) {
          if (
            document.activeElement.id == inputs[i].id &&
            i + 1 < inputs.length
          ) {
            inputs[i + 1].focus();
            break;
          }
        }
      }
    };
    document.addEventListener("keydown", function (event) {
      if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
        var form = event.target.form;
        var index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 1].focus();
        event.preventDefault();
      }
    });
    //firebase.auth().onAuthStateChanged((user) => {
    let user = currentUser;
    if (user !== null) {
      let displayName = user.displayName;

      let parts = window.location.pathname.split("/");
      // let parts = window.location.pathname.split("/");
      let lastSegment = parts.pop() || parts.pop();
      // if (isLoggedInLocal() && lastSegment == "") {
      //   // AuthenticationStore.setIsLoggedIn((prev) => !prev);
      // }
      // AuthenticationStore.setIsLoggedIn(true);
      // if (alreadyLoaded == false) {
      //   alreadyLoaded = true;
      //   window.location.href = "/";
      // }

      if (user.emailVerified === true) {
        AuthenticationStore.setIsLoggedIn(true);

        const isLoggedInWithEmail =
          user.providerData[0].providerId === "password";

        if (!isLoggedInWithEmail) {
          if (displayName !== null) {
            if (!displayName.match(" ") === true) {
              /*hack needs fixing*/

              AuthenticationStore.setShowModal(false);
            } else {
              AuthenticationStore.setIsLoggedIn(true);
            }
          }
        } else {
          if (user.displayName === null) {
            AuthenticationStore.setIsLoggedIn(true);

            // console.log("[LoginM] Login user", user);
          }
        }

        setUrlPhoto(currentUser.photoURL);

        localStorage.setItem(
          "ProfilePhoto",
          JSON.stringify({
            profilePhoto: user.photoURL,
          })
        );

        // setShowFullName(isLoggedInWithEmail ? true : false);
        // setFullNameHidden(user.providerData[0].displayName);
        // localStorage.setItem(
        //   "fullname",
        //   JSON.stringify({ fullname: user.providerData[0].displayName })
        // );
        // fullnameg = user.providerData[0].displayName;
        firebase
          .firestore()
          .collection("users")
          .doc(user.uid)
          .get()
          .then((doc) => {
            if (doc.exists) {
              let data = doc.data();
              let username = data.username;
              if (username != null && username != "") {
                setUsername(username);
                AuthenticationStore.setShowModal(false);
              }
              return;
            }

            firebase
              .firestore()
              .collection("users")
              .doc(user.uid)
              .set({ userId: user.uid });

            firebase.auth().currentUser.updateProfile({
              displayName: "",
            });

            //setIsLoggedIn(true);
            setUsername("");
          });
      } else {
        setEmailVerification(true);
      }
    }
    return () => {
      // Clean up or cancel any ongoing tasks or subscriptions here
    };
    //});
  }, [currentUser]);

  // if (emailVerification === true) {
  //   return (
  //     <div
  //       style={{
  //         height: "100%",
  //         width: "100%",
  //         display: "flex",
  //         justifyContent: "center",
  //         alignItems: "center",
  //         flexDirection: "column",
  //         padding: 50,
  //         background:'#1C1C1C'
  //       }}
  //     >
  //       <FontAwesomeIcon
  //         icon={faEnvelope}
  //         color="#FEFFFE"
  //         size={"7x"}
  //         style={{ display: "flex", cursor: "pointer", marginBottom: 10 }}
  //       />
  //       <p style={{ color: "#A962F1", fontSize: 20 }}>
  //         Email verification needed!
  //       </p>

  //       <p
  //         style={{
  //           color: "white",
  //           fontFamily: "quicksand",
  //           fontSize: 15,
  //           maxWidth: 430,
  //           textAlign: "center",
  //           margin: 20,
  //         }}
  //       >
  //         In order to continue using Flowroom, you must verify your email
  //         address via a verification link we'll send you.
  //       </p>
  //       <div
  //         onClick={() => {
  //           let email = localStorage.getItem("emailUsing");

  //           firebase
  //             .firestore()
  //             .collection("emails")
  //             .doc(email)
  //             .set({ verified: false }, { merge: true })
  //             .then(() => {
  //               // firebase
  //               //   .firestore()
  //               //   .collection("emails")
  //               //   .doc("email")
  //               //   .onSnapshot((doc) => {
  //               //     console.log("Current data: ", doc.data());
  //               //   });
  //               setSendVerifyButton(true);
  //               firebase
  //                 .firestore()
  //                 .collection("emails")
  //                 .doc(email)
  //                 .onSnapshot((doc) => {
  //                   if (doc.data().verified === true) {
  //                     setEmailVerification(false);
  //                   }
  //                 });
  //             });

  //           firebase.auth().currentUser.sendEmailVerification();
  //         }}
  //         style={{
  //           height: 28,
  //           width: "100%",
  //           maxWidth: 300,
  //           backgroundColor: sendVerifyButton === false ? "#A962F1" : "#6371F6",
  //           borderRadius: 20,
  //           fontFamily: "quicksand",
  //           color: "white",
  //           textAlign: "center",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           display: "flex",
  //           justifyContent: "center",
  //           alignItems: "center",
  //           cursor: "pointer",
  //         }}
  //       >
  //         <p
  //           style={{ color: "#222222", fontFamily: "quicksand", fontSize: 15 }}
  //         >
  //           {sendVerifyButton === false
  //             ? "Verify email address"
  //             : "Resend verification email"}
  //         </p>
  //       </div>
  //     </div>
  //   );
  // }

  if (currentUser == null && isPasswordRecover) {
    return (
      <div
        style={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: 50,
          background:'#1C1C1C'
        }}
      >
        <p
          style={{
            fontSize: 20,
            color: "#FEFFFE",
            fontFamily: "quicksand",
            top: 25,
            left: 25,
            position: "absolute",
          }}
        >
          Password Reset
        </p>
        <p
          style={{
            fontSize: 12.7,
            color: "#FEFFFE",
            fontFamily: "quicksand",
            padding: "0px 0px 20px",
            marginTop: 25,
          }}
        >
          Please enter your email below, so that we can send you a link to reset your password. Resend if it does not appear within 5 minutes.
        </p>
        <input
          type="text"
          id="email"
          className="formInputOutline"
          style={{
            border: "0px solid #545454",
            overflow: "hidden",
            borderRadius: 10,
            backgroundColor: "#2D2A2B",
            color: "white",
            height: 33,
            width: "100%",
            paddingLeft: 10,
            fontFamily: "quicksand",
            textTransform: "lowercase",
          }}
          placeholder="Email"
        />
                <p style={{color:'#F06263', fontFamily:'quicksand', fontSize:14, marginTop:10}}>{errorMessagePasswordReset}</p>

        {sendPasswordBtn ? (
          <div
            onClick={handleSendClick}
            style={{
              backgroundColor: "#F06263",
              width: "100%",
              borderRadius: 10,
              height: 30,
              marginTop: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            <p
              style={{
                color: "#222222",
                fontFamily: "quicksand",
                fontSize: 15,
              }}
            >
              Send
            </p>
          </div>
        ) : (
          <div
            style={{
              backgroundColor: "#A962F1",
              width: "100%",
              borderRadius: 10,
              height: 30,
              marginTop: 20,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              cursor: countdown > 0 ? "default" : "pointer",
            }}
            onClick={() => { if(countdown === 0) setSendPasswordBtn(true); }}
          >
            <p
              style={{
                color: "#222222",
                fontFamily: "quicksand",
                fontSize: 15,
              }}
            >
              {countdown > 0 ? `Resend in ${Math.floor(countdown / 60)}:${('0' + countdown % 60).slice(-2)}` : "Resend"}
            </p>
          </div>
        )}
      </div>
    );
  } 



  if(currentUser == null && showUpWithEmail) {
    return(          <div
      style={{
        margin: "auto",
        backgroundColor:'#1c1c1c'
      }}
    >
       <div
              style={{
                display: "flex",
                height: 58,
                borderBottom:'4px solid #000',
                paddingTop:20,
              }}
            >
              {/* <p
                style={{
                  color: "white",
                  fontSize: 24,
                  marginRight: 5.8,
                  fontFamily: "quicksand",
                }}
              >
                {!login ? "Sign in" : "Sign up"}
              </p>
              <p
                className="signIn"
                style={{
                  color: "white",
                  fontSize: 24,
                  marginBottom: 20,
                  fontFamily: "quicksand",
                }}
              >
                {!login ? "to" : "with"} Flowroom
              </p> */}
              <img src="../images/sign-in-icon.svg" style={{    fontSize: 10,
    height: 20,
    margin: 2,
    marginLeft: 15,
    marginRight: 10,
   
              }}/>
              <p
                style={{
                  color: "white",
                  fontSize: 17,
                  fontFamily: "quicksand",
                }}
              >
                Log in or sign up
              </p>
              
            </div>
      
      
      <div style={{borderTop: '1px solid rgb(62, 62, 62)'}}>
      <p style={{ fontFamily:'quicksand',
    fontSize: 14,
    position: 'relative',     
    margin: 'auto',
    color: 'white',
    fontFamily: 'quicksand',
    fontSize: 14,
    position: 'relative',
    top: 20,
    /* left: 25px; */
    textAlign: 'left',
    width: 'calc(100% - 50px)'}}>Type the email address you want to login or sign up with.</p>
    <p style={{color:"#F06263", 
          fontSize: 12,
          fontFamily: "quicksand",
          marginTop: 5,
          position: 'relative',
          top: 30,
          left: 25,
          width:'calc(100% - 45px)',
          marginBottom:10
          
          }}>{errorMessage}</p>
      <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>   
      <input
        type="text"
        id="email-check"
        className="formInputOutline"
        style={{
          border: "0px solid #545454",
          overflow: "hidden",
          borderRadius: 10,
          backgroundColor: "#323232",
          color: "white",
          height: 30,
          width:'calc(100% - 50px)',
          paddingLeft: 10,
          fontFamily: "quicksand",
          textTransform: "lowercase",
          marginTop:40,
        }}
        onChange={(e) => {
          const value = e.target.value;
          const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
          setEmailCheck(value.trim());
          if (!emailPattern.test(value.trim()) && value.trim() !== "") {
            setErrorMessage("Please enter a valid email address without spaces or invalid characters.");
          } else {
            setErrorMessage("");
          }
        }}
        value={emailCheck}
        placeholder="Type your email address"
        autoComplete="off"
      />
     <div
    onClick={debounce(async () => {
        if (isCooldownActive) {
            setErrorMessage("Please wait a moment before trying again.");
            return;
        }

        if (!emailCheck) {
            setErrorMessage("Please enter a valid email address before proceeding.");
            return;
        }

        startCooldown(3000); // 3-second cooldown

        const isInviteAccepted = await checkTheUserInviteStatus(emailCheck);
        if (!isInviteAccepted) {
            setErrorMessage(
                "You cannot proceed without an invite. If you’ve already requested one, you’ll receive an email with further instructions once it’s accepted by our team. If you haven’t requested an invite yet, please select 'Cancel' and fill out the invite request form to submit your request. You will be able to continue once your invite is accepted."
            );
            return; // Stops further execution in this async function
        }
        checkEmailStatus();
    }, 500)} // Debounce with 500ms delay
    style={{
        background: "#4FD975",
        height: 27,
        marginTop: 15,
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        pointerEvents: "all",
        cursor: "pointer",
        marginBottom: 10,
        width: "calc(100% - 50px)",
    }}
>
    <p
        style={{
            color: "#222222",
            fontSize: 15,
            fontFamily: "quicksand",
            pointerEvents: "none",
        }}
    >
        Continue
    </p>
</div>
      <div
        onClick={() => {
          // handleSubmit()
          setShowUpWithEmail(false);
          setShowSignUp(false);
          setShowLogin(false);
          setShowAuthMain(true);
          setErrorMessage("");
         
        }}
        style={{
          background: "rgb(240, 98, 99)",
          height: 27,
          marginTop: 0,
          marginBottom: 20,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: "all",
          cursor: "pointer",
          width:'calc(100% - 50px)',
        }}
      >
        <p
          style={{
            color: "#222222",
            fontSize: 15,
            fontFamily: "quicksand",
            pointerEvents: "none",
          }}
        >
          Cancel
        </p>
      </div>
      </div>   
      </div>


    </div>)
  }


  if(currentUser == null && showLogin) {
    return(          <div
      style={{
        margin: "auto",
        backgroundColor:'#1c1c1c'
      }}
    >
       <div
              style={{
                display: "flex",
                height: 59,
                borderBottom:'4px solid #000',
                paddingTop:20,
                
              }}
            >
              {/* <p
                style={{
                  color: "white",
                  fontSize: 24,
                  marginRight: 5.8,
                  fontFamily: "quicksand",
                }}
              >
                {!login ? "Sign in" : "Sign up"}
              </p>
              <p
                className="signIn"
                style={{
                  color: "white",
                  fontSize: 24,
                  marginBottom: 20,
                  fontFamily: "quicksand",
                }}
              >
                {!login ? "to" : "with"} Flowroom
              </p> */}
              <img src="../images/sign-in-icon.svg" style={{    fontSize: 10,
    height: 20,
    margin: 2,
    marginLeft: 15,
    marginRight: 10
              }}/>
              <p
                style={{
                  color: "white",
                  fontSize: 17,
                  fontFamily: "quicksand",
                }}
              >
                Log in or sign up
              </p>
            </div>



<div style={{padding:20,     borderTop: '1px solid rgb(62, 62, 62)'}}>


      <p style={{fontFamily:'quicksand', fontSize:14, fontWeight:'normal', color:'white', marginBottom:10}}>Email</p>
      <input
        type="text"
        id="email"
        className="formInputOutline"
        style={{
          border: "0px solid #545454",
          overflow: "hidden",
          borderRadius: 10,
          backgroundColor: "#323232",
          color: "white",
          height: 37,
          width: "100%",
          paddingLeft: 10,
          fontFamily: "quicksand",
          textTransform: "lowercase"
        }}
        onChange={handleInputChange} // Set the onChange handler
        value={emailCheck}
        
        // placeHolder="Email"
      />
      <p
        style={{
          color: "#F06263",
          fontSize: 14,
          fontFamily: "quicksand",
          marginTop: 5,
        }}
      >
        {errorMessage}
      </p>
      <div
        style={{
          maxWidth: 800,
          width: "100%",
          display: "flex",
          marginTop: 17,
          flexDirection: "column",
        }}
      >
      <p style={{fontFamily:'quicksand', fontSize:14, fontWeight:'normal', color:'white', marginBottom:10}}>Password</p>
        <input
          type="password"
          id="password"
          className="formInputOutline"
          style={{
            border: "0px solid #545454",
            overflow: "hidden",
            borderRadius: 10,
            backgroundColor: "#323232",
            color: "white",
            height: 37,
            width: "100%",
            paddingLeft: 10,
            fontFamily: "quicksand",
          }}
          // placeHolder="Password"
        />
        <p
          onClick={() => {
            AuthenticationStore.setIsLoggedIn(false);
            setIsPasswordRecover(true);
          }}
          style={{
            color: "#F06263",
            fontFamily: "quicksand",
            fontSize: 12,
            marginTop: 10,
            marginLeft: 0,
            cursor: "pointer",
            marginBottom:20,
            opacity:0.7
          }}
        >
          Forgot your password?
        </p>
      </div>
      <p
        style={{
          fontSize: 14,
          color: "#F06263",
          fontFamily: "quicksand",
          marginTop: 5,
          marginBottom:20
        }}
      >
        {passwordErrorMessage}
      </p>
<div
style={{
display: "flex",
alignItems: "center",
}}
>
<ReCAPTCHA
sitekey="6Lf2Gk8UAAAAAM0PYHAgWAi_zW3EKWGD0ZkTGjDC"
onChange={handleRecaptcha}
theme="dark"
size="compact"
/>
</div>
      <div
        onClick={() => {
          handleSubmit()
         
        }}
        style={{
          background: "#4FD975",
          height: 27,
          marginTop: 15,
          marginBottom: 20,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: "all",
          cursor: "pointer",
          marginBottom:10

        }}
      >
        <p
          style={{
            color: "#222222",
            fontSize: 15,
            fontFamily: "quicksand",
            pointerEvents: "none",
          }}
        >
          Continue
        </p>
      </div>
      <div
        onClick={() => {
          // handleSubmit()
          setShowUpWithEmail(false);
          setShowSignUp(false);
          setShowLogin(false);
      
        }}
        style={{
          background: "rgb(240, 98, 99)",
          height: 27,
          width: "100%",
          marginTop: 10,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: "all",
          cursor: "pointer",
        }}
      >
        <p
          style={{
            color: "#222222",
            fontSize: 15,
            fontFamily: "quicksand",
            pointerEvents: "none",
          }}
        >
          Cancel
        </p>
      </div>

</div>

    </div>)
  }


  if(currentUser == null && showSignUp) {
    return(          <div
      style={{
        margin: "auto",
        backgroundColor:'#1c1c1c'
      }}
    >
    <SigninM closeSignUp={()=>{
      setShowUpWithEmail(true);
    }} emailAddress={emailCheck}/>

    </div>)
  }



  if(currentUser == null && showUpWithPhone) {
    return(          <div
      style={{
        margin: "auto",
        backgroundColor:'#1c1c1c'
      }}
    >
           <div
              style={{
                display: "flex",
                height: 55,
                borderBottom:'4px solid #000',
                paddingTop:20
              }}
            >
              {/* <p
                style={{
                  color: "white",
                  fontSize: 24,
                  marginRight: 5.8,
                  fontFamily: "quicksand",
                }}
              >
                {!login ? "Sign in" : "Sign up"}
              </p>
              <p
                className="signIn"
                style={{
                  color: "white",
                  fontSize: 24,
                  marginBottom: 20,
                  fontFamily: "quicksand",
                }}
              >
                {!login ? "to" : "with"} Flowroom
              </p> */}
              <img src="../images/sign-in-icon.svg" style={{    fontSize: 10,
    height: 20,
    margin: 2,
    marginLeft: 15,
    marginRight: 10
              }}/>
              <p
                style={{
                  color: "white",
                  fontSize: 17,
                   fontFamily: "quicksand",
                }}
              >
Log in or sign up              </p>
            </div>



<div style={{padding:20, 
    height: '100%',
    minHeight: '340px'}}>



      <div
        onClick={() => {
          // handleSubmit()
          setShowUpWithPhone(false);
          setShowUpWithEmail(false);
          setShowSignUp(false);
          setShowLogin(false);
         
        }}
        style={{
          background: "#4FD975",
          height: 27,
          width: "100%",
          marginTop: 20,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: "all",
          cursor: "pointer",
        }}
      >
        <p
          style={{
            color: "#222222",
            fontSize: 15,
            fontFamily: "quicksand",
            pointerEvents: "none",
          }}
        >
          Cancel
        </p>
      </div>

</div>

    </div>)
  }






  if (currentUser == null && !isPasswordRecover && showAuthMain) {
    return (
      <div style={{ flex: "1", display: "flex"}}>
        <div style={{ flex: "1", display: "flex" }}>
          <div
            className="main-section-wrap-signup-screen"
            style={{
              width: "100%",

              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                height: 55,
                borderBottom:'4px solid #000',
                alignItems:'center'
              }}
            >
              {/* <p
                style={{
                  color: "white",
                  fontSize: 24,
                  marginRight: 5.8,
                  fontFamily: "quicksand",
                }}
              >
                {!login ? "Sign in" : "Sign up"}
              </p>
              <p
                className="signIn"
                style={{
                  color: "white",
                  fontSize: 24,
                  marginBottom: 20,
                  fontFamily: "quicksand",
                }}
              >
                {!login ? "to" : "with"} Flowroom
              </p> */}
             <img src="../images/sign-in-icon.svg" style={{    fontSize: 10,
    height: 20,
    margin: 2,
    marginLeft: 15,
    marginRight: 10
              }}/>
              <p
                style={{
                  color: "white",
                  fontSize: 17,
                  fontFamily: "quicksand",
                }}
              >
Log in or sign up              

</p>

            </div>
            <div class="auth-modal-wrapper">

            <div className="main-section-signup-box auth-modal" style={{borderTop:'1px solid #3E3E3E',  overflowY:'scroll'}} ref={modalRef}>
              <div className="login-screen-wrap">
                <div className="main-section-wrap-signup-screen">
                  <div></div>

                  <div
                    className="main-section-login-box"
                    style={{
                      backgroundColor: "#202020",
                      border: "none",
                    }}
                  >
                    <div
                      className="login-section-fields"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <div
                        style={{
                          position: "relative",
                          maxWidth: "100%",
                          width: "100%",
                          margin: "auto",
                        }}
                      >
                       
                      { inviteOnlyMode == true  && requestAccepted == false && showInviteForm == false ? (<div style={{display:'flex', width:'100%', justifyContent:'center', alignItems:'center', marginTop:20, marginBottom:20, flexDirection:'column'}}> 
                        <img src="../../invite-logo.png" style={{height:45}} />
                        </div>):""}
                        <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
                        {inviteOnlyMode == true && requestAccepted == false ? (<div style={{marginTop: showInviteForm == false ? 0: 20, display:'flex', alignItems:'center'}}>
                          <p style={{fontFamily:'quicksand', color:'#fff'}}>Flowroom is currently invite only</p>
                        </div>):""}
                        </div>
                        {requestAccepted == true ? (<div style={{padding:20, position:'relative', width:'100%', display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>
                        <FontAwesomeIcon
                icon={faCheck}
                color="#FEFFFE"
                style={{ display: "flex", marginBottom: 10, fontSize: 80 }}
              />
                          <p style={{color:'white', fontFamily:'quicksand', fontSize:14, marginBottom:20}}>
                          Your invite request is now on our waiting list! We're excited about your interest and are diligently working to accommodate as many users as possible. Please note that while your request is under review, you may not receive the invite code immediately. In the meantime, make sure to check both your inbox and spam folder for updates. We appreciate your patience and enthusiasm!
                          </p>
                    
                          <div
        onClick={() => {
          // handleSubmit()
         setRequestAccepted(false);
         
        }}
        style={{
          background: "#4FD975",
          height: 27,
          width: "100%",
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: "all",
          cursor: "pointer",
          margin:'auto',
          marginTop:15,
          marginBottom:20
        }}
      >
        <p
          style={{
            color: "#222222",
            fontSize: 15,
            fontFamily: "quicksand",
            pointerEvents: "none",
          }}
        >
          Ok
        </p>
      </div>

                        </div>):""}
                        </div>

                        {/* {showAuthMain ? (<PhoneSignIn phoneConfirmation={()=>{
                          setHideOptions(true);

                        }} showOptions={()=>{
                          setHideOptions(false);
                          
                        }}/>):""} */}

              
                        {/* <p
                          style={{
                            color: "white",
                            fontSize: 25,
                            fontFamily: "quicksand",
                            marginTop: 15,
                            marginLeft: 30,
                            marginBottom: 20,
                          }}
                        >
                          Or
                        </p> */}
                        {/* {!hideOptions ? (<div style={{display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 30,
    marginRight: 30,
    marginBottom: 45,
    marginTop: 43
}}>
                          <div style={{backgroundColor:'#3E3E3E', width:'100%', height:2, marginRight:30}}></div>
                          <p style={{fontFamily:'quicksand', color:'#FFF', opacity:0.3, fontSize:14}}>OR</p>
                          <div style={{backgroundColor:'#3E3E3E', width:'100%', height:2, marginLeft:30}}></div>
                        </div>):""} */}
                        {!hideOptions && requestAccepted == false ? (<div
                          style={{
                            position: "relative",
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                            bottom: showInviteForm == false ? 27 : 40,
                            marginTop:showInviteForm == false ? 0 : 50
                          }}
                        >
                          {/* <FirebaseAuth
                            uiCallback={(ui) => ui.disableAutoSignIn()}
                            uiConfig={uiConfig}
                            firebaseAuth={firebase.auth()}
                          /> */}
                          <div
                            style={{
                              paddingLeft: 30,
                              paddingRight: 30,
                              marginBottom: inviteOnlyMode == false ? 0 : 50,
                              marginTop: inviteOnlyMode == false ? 10 : 0
                            }}
                          >
                        {hideOptions ? (<div onClick={()=>{
                          setShowUpWithPhone(true);
                        }} style={{
                          backgroundColor: "#f06263",
                          overflow: "inherit",
                          position: "relative",
                          fonFamily: "IBMPlexSans, sans-serif !important",
                          fontSize: 14,
                          fontWeight: 600,
                          letterSpacing: 0.5,
                          lineHeight: 32,
                          height: 37,
                          color: "#222222",
                          display: "inline-block",
                          border: "none",
                          textAlign: "center",
                          /* background: #0079d3; */
                          cursor: "pointer",
                          lineHeight: "unset",
                          minHeight: 35,
                          width: "100%",
                          /* min-width: 155px; */
                          padding: "5px 10px",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          display: "-webkit-flex",
                          display: "flex",
                          webkitAlignItems: "center",
                          alignItems: "center",
                          webkitJustifyContent: "none",
                          justifyContent: "none",
                          borderRadius: 10,
                          justifyContent: "center",
                          marginBottom: 15,
                        }}>      <p
                        style={{
                          fontSize: 15,
                          fontFamily: "quicksand",
                          color: "#222222",
                          top: 17,

                          pointerEvents: "none",
                        }}
                      >
                        Continue with Phone
                      </p></div>):""}
                      <p style={{color:'#DF6B68', fontFamily:'quicksand', fontSize:14, marginBottom:10}}>{errorMessage}</p>
                      <div style={{marginBottom:10, width:'100%', overflowY:"scroll", display:'flex', flexDirection:'column'}}>
         <div style={{height:'100%', display:'flex', marginLeft:4, marginTop:4, marginRight:4, marginBottom:10}}>               
         {requestAccepted == false && inviteOnlyMode == true && showInviteForm == true ? (
  <input
    type="text"
    id="inviteFirstName"
    className="formInputOutline"
    style={{
      border: "0px solid #545454",
      overflow: "hidden",
      borderRadius: 10,
      backgroundColor: "#2D2A2B",
      color: "white",
      height: 33,
      width: "100%",
      paddingLeft: 10,
      fontFamily: "quicksand",
      textTransform: "none", // Ensures placeholder text styling is unaffected
      marginRight: 20,
    }}
    onChange={(e) => {
      // Clean and format the input
      let value = e.target.value.replace(/[^a-zA-Z-'\s]/g, ""); // Removes invalid characters
      if (value.length > 0) {
        value = value.charAt(0).toUpperCase() + value.slice(1); // Capitalizes the first letter
      }
      setInviteFirstName(value);
      e.target.value = value; // Updates the input field to display the cleaned and formatted value
    }}
    autoComplete="off"
    placeholder="First Name" // Keeps the placeholder text starting with a capital 'F'
  />
) : (
  ""
)}
{requestAccepted == false && inviteOnlyMode == true && showInviteForm == true ? (
  <input
    type="text"
    id="inviteLastName"
    className="formInputOutline"
    style={{
      border: "0px solid #545454",
      overflow: "hidden",
      borderRadius: 10,
      backgroundColor: "#2D2A2B",
      color: "white",
      height: 33,
      width: "100%",
      paddingLeft: 10,
      fontFamily: "quicksand",
      textTransform: "none", // Ensures placeholder text styling is unaffected
    }}
    onChange={(e) => {
      // Clean and format the input
      let value = e.target.value.replace(/[^a-zA-Z-'\s]/g, ""); // Removes invalid characters
      if (value.length > 0) {
        value = value.charAt(0).toUpperCase() + value.slice(1); // Capitalizes the first letter
      }
      setInviteLastName(value);
      e.target.value = value; // Updates the input field to display the cleaned and formatted value
    }}
    autoComplete="off"
    placeholder="Last Name" // Keeps the placeholder text starting with a capital 'L'
  />
) : (
  ""
)}

                      </div>
                      {inviteOnlyMode == true && requestAccepted == false && showInviteForm == true ? (
  <input
    type="text"
    id="inviteEmail"
    className="formInputOutline"
    style={{
      border: "0px solid #545454",
      overflow: "hidden",
      borderRadius: 10,
      backgroundColor: "#2D2A2B",
      color: "white",
      height: 33,
      width: "calc(100% - 10px)",
      paddingLeft: 10,
      fontFamily: "quicksand",
      textTransform: "none", // Ensures placeholder text styling is unaffected
      marginLeft: 4,
      marginRight: 4,
    }}
    onInput={(e) => {
      e.target.value = e.target.value.toLowerCase(); // Force lowercase in real-time
    }}
    onChange={(e) => {
      const value = e.target.value.trim().toLowerCase(); // Enforce lowercase and trim spaces
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Validates email format (e.g., user@example.com)

      if (value === "" || emailRegex.test(value)) {
        setInviteEmail(value); // Updates state only for valid input
        e.target.style.border = "0px solid #545454"; // Reset border if valid
        setErrorMessage(""); // Clear error message for valid input
      } else {
        e.target.style.border = "2px solid red"; // Highlights the input for invalid email
        setErrorMessage("Please enter a valid email address."); // Displays an error message
      }
    }}
    autoComplete="off"
    autocapitalize="none" // Prevents auto-capitalization on mobile devices
    inputMode="email" // Provides a proper email keyboard on mobile
    placeholder="Email to send invite to" // Placeholder with a capital "E"
  />
) : null}





         {inviteOnlyMode == true && requestAccepted == false && showInviteForm == true ? (<textarea
          type="text"
          id="inviteInfo"
          className="formInputOutline"
          style={{
            border: "0px solid #545454",
            overflow: "hidden",
            borderRadius: 10,
            backgroundColor: "#2D2A2B",
            color: "white",
            height: 55,
            width: "calc(100% - 10px)",
            paddingLeft: 10,
            fontFamily: "quicksand",
            marginTop:10,
            marginBottom:10,
            resize:'none',
            paddingTop:10,
            marginLeft:4
          }}
          onChange={e => setInviteInfo(e.target.value)}
          placeholder="Tell us about yourself. What are you interested in flowroom?"
          autoComplete="off"
          maxLength={150}
        ></textarea>):""}
</div>
<div style={{marginLeft:5, position:'absolute', bottom:100}}>
          { !requestAccepted && inviteOnlyMode == true ? (    <form onSubmit={handleInviteSubmit}>
            <ReCAPTCHA
                  ref={recaptchaRef}
sitekey="6LeNzrUqAAAAAJ19y_fHF8vsbFQEDNECT_q6ujer"
onChange={handleRecaptchaVerify}
theme="dark"
size="invisible"
/></form>):""}
</div>
            {inviteOnlyMode == true && showInviteForm == false ? (<button
  onClick={() => {
    setShowInviteForm(true);
  }}
  disabled={isDisabled || isInviteLoading} // Disable button when loading or explicitly disabled
  style={{
    background: isDisabled || isInviteLoading ? "#ccc" : "#fff",
    height: 27,
    width: "calc(100% - 110px)",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: isDisabled || isInviteLoading ? "not-allowed" : "pointer",
    margin: "auto",
    marginTop: 15,
    marginBottom: 20,
    border: "1px solid #5B5AD8",
    position: "relative",
    color: "#5B5AD8",
    fontSize: 15,
    fontFamily: "quicksand",
    fontWeight:'bold'
  }}
>
Request an Invite
  {isInviteLoading ? (
    <div className="invite-spinner"></div>
  ) :""}
</button>):""}

{inviteOnlyMode == true && requestAccepted == false && showInviteForm == true ? (<button
  onClick={handleInviteSubmit}
  disabled={isDisabled || isInviteLoading} // Disable button when loading or explicitly disabled
  style={{
    background: isDisabled || isInviteLoading ? "#ccc" : "#fff",
    height: 27,
    width: "calc(100% - 10px)",
    borderRadius: 10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: isDisabled || isInviteLoading ? "not-allowed" : "pointer",
    margin: "auto",
    marginTop: 15,
    marginBottom: 20,
    border: "1px solid #5B5AD8",
    position: "relative",
    color: "#5B5AD8",
    fontSize: 15,
    fontFamily: "quicksand",
    fontWeight:'bold'
  }}
>
Submit an Invite
  {isInviteLoading ? (
    <div className="invite-spinner"></div>
  ) :""}
</button>):""}
{inviteOnlyMode == true && requestAccepted == false && showInviteForm == true ? (<div
        onClick={() => {
          if(cancelDisabled) {
            setErrorMessage("Your invite request is being processed and can’t be canceled right now.");
            return;
          }
          // handleSubmit()
          setShowUpWithEmail(false);
          setShowSignUp(false);
          setShowLogin(false);
          setShowAuthMain(true);
          setErrorMessage("");
          setShowInviteForm(false)
         
        }}
        style={{
          background: "rgb(240, 98, 99)",
          height: 27,
          marginTop: 0,
          marginBottom: 20,
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: "all",
          cursor: "pointer",
          width:'calc(100% - 11px)',
          left:5,
          position:'relative'
        }}
      >
        <p
          style={{
            color: "#222222",
            fontSize: 15,
            fontFamily: "quicksand",
            pointerEvents: "none",
            fontWeight:'bold'
          }}
        >
          Cancel
        </p>
      </div>):""}
       {/* <button onClick={handleInviteSubmit} type="submit">Submit</button> */}

    
      {inviteOnlyMode == true  && requestAccepted == false  && showInviteForm == false ? (<div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>
          <p style={{color:'white', fontFamily:'quicksand', fontSize:14}}>Or if you already accepted an Invite</p>
      </div>):""}
                      {requestAccepted === false && showInviteForm == false ? (<div
        onClick={() => {
          // handleSubmit()
        setErrorMessage("");
         setShowUpWithEmail(true);
         
        }}
        style={{
          background: "#4FD975",
          height: 27,
          width: "calc(100% - 110px)",
          borderRadius: 10,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          pointerEvents: "all",
          cursor: "pointer",
          margin:'auto',
          marginTop:15,
          marginBottom:20,
          position:'relative',
          whiteSpace:'nowrap'
        }}
      >
         {/* <div
    style={{
      backgroundImage: "url(/google.svg)",
      height: "15px",
      width: "22px",
      left: 25,
      top: "12px",
      marginRight: "10px",
      backgroundRepeat:'no-repeat'
    }}
  ></div> */}
        
        <p
          style={{
            color: "#222222",
            fontSize: 12,
            fontFamily: "quicksand",
            pointerEvents: "none",
          }}
        >
          Continue with Email
        </p>
      </div>):""}
          {inviteOnlyMode == false && requestAccepted == false  ? (<div
              onClick={async () => {
             
                firebase.auth().signInWithPopup(new firebase.auth.GoogleAuthProvider())
                  .then(async (result) => {
                    var user = result.user;
                    localStorage.setItem("emailUsing", user.email.trim().toLocaleLowerCase());
                    var usersRef = firebase.firestore().collection('users').where('email', '==', user.email);
                    usersRef.get().then((querySnapshot) => {
                      if (!querySnapshot.empty) {
                        var userProfile = querySnapshot.docs[0].data();
                        user.updateProfile({
                          displayName: userProfile.username ? userProfile.username : user.displayName,
                          photoURL: userProfile.urlPhoto ? userProfile.urlPhoto : user.photoURL,
                        });
                      } else {
                        console.log("No matching user found in Firestore. Proceed with creating a new user document or additional logic.");
                      }
                    });
                  })
                  .catch((error) => {
                    if (error.code === 'auth/account-exists-with-different-credential') {
                      var email = error.email;
                      firebase.auth().fetchSignInMethodsForEmail(email).then((methods) => {
                        if (methods.indexOf(firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD) !== -1) {
                          var credential = error.credential;
                          firebase.auth().signInWithCredential(credential).then(() => {
                            console.log("Accounts successfully linked.");
                          });
                        }
                      });
                    }
                  });

                
              }}
              
              
                              style={{
                                backgroundColor: "#f06263",
                                overflow: "inherit",
                                position: "relative",
                                fonFamily: "IBMPlexSans, sans-serif !important",
                                fontSize: 14,
                                fontWeight: 600,
                                letterSpacing: 0.5,
                                lineHeight: 32,
                                height: 27,
                                color: "#222222",
                                display: "inline-block",
                                border: "none",
                                textAlign: "center",
                                /* background: #0079d3; */
                                cursor: "pointer",
                                lineHeight: "unset",
                                minHeight: 27,
                                width: "calc(100% - 110px)",
                                /* min-width: 155px; */
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                display: "-webkit-flex",
                                display: "flex",
                                webkitAlignItems: "center",
                                alignItems: "center",
                                webkitJustifyContent: "none",
                                justifyContent: "none",
                                borderRadius: 10,
                                justifyContent: "center",
                                marginBottom: 15,
                                marginLeft:'auto',
                                marginRight:'auto',
                                marginBottom:20
                              }}
                            >
                              <div
                                style={{
                                  backgroundImage: "url(/google.svg)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "100%",
                                  height: "15px",
                                  width: "15px",
                                  marginRight: "10px",
                              
                                }}
                              ></div>
                              
                              <p
                                style={{
                                  fontSize: 15,
                                  fontFamily: "quicksand",
                                  color: "#222222",
                                  top: 17,

                                  pointerEvents: "none",
                                }}
                              >
                                Continue with Google
                              </p>
                            </div>):""}

                            
                            {inviteOnlyMode == false  && requestAccepted == false ? (<div
onClick={() => {
  var provider = new firebase.auth.GithubAuthProvider();

  firebase.auth().signInWithPopup(provider)
    .then((result) => {
      var token = result.credential.accessToken; // GitHub Access Token
      var user = result.user; // Signed-in user info
      localStorage.setItem("emailUsing", user.email.trim().toLowerCase());
  
      // Check if the user exists with another auth method
      return firebase.auth().fetchSignInMethodsForEmail(user.email)
        .then((signInMethods) => {
          if (!signInMethods.includes('github.com') && signInMethods.length > 0) {
            // User has other sign-in methods linked already
            var credential = firebase.auth.GithubAuthProvider.credential(token);
            // Link the GitHub account to the already signed-in user
            return firebase.auth().currentUser.linkWithCredential(credential)
              .then((usercred) => {
                var user = usercred.user;
                console.log("Account linking success", user);
              })
              .catch((linkError) => {
                console.error("Account linking error", linkError);
              });
          } else {
            console.log("User signed in with GitHub only or already linked.");
          }
        });
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      var email = error.email;
      var credential = error.credential;
      if (errorCode === "auth/account-exists-with-different-credential") {
        // User has already signed up with a different provider for the same email
        alert("You have already signed up with a different provider for that email. Please log in with that provider.");
        firebase.auth().fetchSignInMethodsForEmail(email).then((methods) => {
          if (methods.includes('google.com')) {
            var googleProvider = new firebase.auth.GoogleAuthProvider(); // Handling Google here for simplicity
            firebase.auth().signInWithPopup(googleProvider).then((result) => {
              result.user.linkWithCredential(credential).then(() => {
                console.log("Accounts successfully linked!");
              }).catch((linkError) => {
                console.error("Error linking accounts", linkError);
              });
            });
          } else {
            console.log("Please log in using your original sign in method to link this GitHub account.");
          }
        });
      } else {
        console.error("GitHub auth error:", error);
      }
    });
  
}}
  style={{
    backgroundColor: "#6371F6",
    overflow: "inherit",
    position: "relative",
    fontFamily: "IBMPlexSans, sans-serif",
    fontSize: 14,
    fontWeight: 600,
    letterSpacing: 0.5,
    lineHeight: 32,
    height: 27,
    color: "#222222",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    minHeight: 27,
    width: "calc(100% - 110px)",
    cursor: "pointer",
    marginBottom: 45,
    margin:'auto'
  }}
>
  <div
    style={{
      backgroundImage: "url(/google.svg)",
      height: "15px",
      width: "15px",
      top: "12px",
      marginRight: "10px",
      backgroundRepeat:'no-repeat'
    }}
  ></div>
  <p
    style={{
      fontSize: 12,
      fontFamily: "quicksand",
      color: "#222222",
      pointerEvents: "none"
    }}
  >
    Continue with GitHub
  </p>
</div>):""}


                          </div>
                          
                        </div>):""} 

                        {/* <div
                          style={{
                            position: "absolute",
                            color: "white",
                            left: 80,
                            top: 57,
                            pointerEvents: "none",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "5px",
                              fontWeight: "bold",
                              fontFamily: "quicksand",
                            }}
                          >
                            {!login ? "SIGN IN" : "SIGN UP"}
                          </p>
                          <p
                            style={{
                              fontWeight: "bold",
                              fontFamily: "quicksand",
                            }}
                          >
                            WITH FACEBOOK
                          </p>
                        </div> */}
                        {/* <div
                          style={{
                            position: "absolute",
                            color: "#757575",
                            left: 80,
                            top: 127,
                            pointerEvents: "none",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "5px",
                              fontWeight: "bold",
                              fontFamily: "quicksand",
                            }}
                          >
                            {!login ? "SIGN IN" : "SIGN UP"}
                          </p>
                          <p style={{ fontWeight: "bold" }}>WITH GOOGLE</p>
                        </div> */}
                        {/* <div
                          style={{
                            position: "absolute",
                            color: "white",
                            left: 80,
                            top: 197,
                            pointerEvents: "none",
                            display: "flex",
                          }}
                        >
                          <p
                            style={{
                              marginRight: "5px",
                              cursor: "pointer",
                              fontWeight: "bold",
                              fontFamily: "quicksand",
                            }}
                          >
                            {!login ? "SIGN IN" : "SIGN UP"}
                          </p>
                          <p style={{ fontWeight: "bold" }}>WITH EMAIL</p>
                        </div> */}
                        {/* <div
                          id="signInLbl"
                          style={{
                            display: "none",
                            margin: "auto",
                            bottom: 15,
                            position: "relative",
                            marginTop: 10,
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <p
                            style={{
                              marginRight: 5,
                              color: "white",
                              fontFamily: "quicksand",
                            }}
                          >
                            {login
                              ? "Already have an account ?"
                              : "Don't have an account ?"}
                          </p>
                          <p
                            onClick={() => {
                              if (login) {
                                setLogin(false);
                              } else {
                                setLogin(true);
                              }
                            }}
                            style={{ color: "white", cursor: "pointer" }}
                          >
                            {login ? "Sign In" : "Sign Up"}
                          </p>
                        </div> */}
                      </div>

                      {/* <div
                        style={{
                          display: !login ? "none" : "none",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <p
                          style={{
                            textAlign: "center",
                            maxWidth: 230,
                            fontFamily: "quicksand",
                          }}
                        >
                          By signing up, you agree to our{" "}
                          <a
                            href="https://about.flowroom.com/terms/"
                            style={{
                              fontWeight: "bold",
                              fontFamily: "quicksand",
                            }}
                          >
                            Terms
                          </a>{" "}
                          and{" "}
                          <a
                            href="https://about.flowroom.com/privacy-policy/"
                            style={{
                              fontWeight: "bold",
                              fontFamily: "quicksand",
                            }}
                          >
                            Privacy Policy
                          </a>
                          .
                        </p>
                      </div> */}
                    </div>



                    {/* <div className="custom-scrollbar" ref={scrollbarRef}></div> */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default React.memo(LoginScreen);
